import 'react-toastify/dist/ReactToastify.css';
import '../../styles/notification/index.scss';
import React from 'react';
import { AlertTriangle, Check } from 'react-feather';
import { cssTransition, toast } from 'react-toastify';

const Zoom = cssTransition({
    enter: 'toast__open',
    exit: 'toast__close',
    collapse: false,
});
export interface NotificationProps {
    color: 'success' | 'danger';
    titleId: string;
    msgId: string;
    dis?: string;
    playSound?: boolean;
}
const Notification: React.FC<NotificationProps> = (props) => { 
    
    return (
        <div
            className={
                props.color === 'success'
                    ? 'alert-success'
                    : 'alert-danger'
            }
        >
            <div className='alert-heading'>
                {!props.dis ? (
                    props.titleId
                ) : (
                    props.msgId
                )}
            </div>
            {!props.dis ? (
                props.msgId
            ) : (
                props.dis
            )}
        </div>
    );
};

export default Notification;

export const notify = (
    type: 'success' | 'danger',
    titleId: string,
    msgId: string,
    description?: any,
    playSound?: boolean,
) => {
    toast(
        <Notification
            color={type}
            msgId={msgId}
            titleId={titleId}
            dis={description}
            playSound={playSound}
        />,
        {
            position: 'top-right',
            // autoClose: type === 'danger' ? 5000 : 3000,
            hideProgressBar: true,
            transition: Zoom,
            closeButton: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: type === 'danger' ? 'error' : 'success',
            icon: type === 'success' ? <Check size={35}/> : <AlertTriangle size={35}/>,
        },
    );
};
