import React from 'react'; 
import { Col, Row } from 'antd'; 
import { IFormProps } from '../../../../../../models/form-props';
import FormElement from '../../../../../../components/form-element';
import { useQuery } from 'react-query';
import patient from '../../../../../../lib/api/patient';
import practitioner from '../../../../../../lib/api/practitioner';

interface MedicationFormProps extends IFormProps { }

const MedicationForm: React.FC<MedicationFormProps> = ({
    control,
    disabled,
}) => { 
    
    const sharedProps = {
        control,
        disabled,
    };

    const getAllPractitioner = useQuery(['practitioner'], 
        () =>  practitioner.getAll({})
    ); 

    return ( 
        <Row>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Name'
                    type='input'
                    name='name'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Response'
                    type='input'
                    name='responce'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Instruction'
                    type='input'
                    name='dosage_instruction'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Rate'
                    type='input'
                    name='dosage_rate'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Dose'
                    type='input'
                    name='dosage_dose'
                    required
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Unit'
                    type='input'
                    name='dosage_unit'
                    required
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Practitioner'
                    type='select'
                    name='practitioner'
                    options={getAllPractitioner?.data?.results?.map((item:any)=> {
                        return {
                            label: item.fname + item.lname,
                            value: item.id
                        }
                    })} 
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Date Asserted'
                    type='datePicker'
                    name='date_asserted'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Effective Period Start'
                    type='datePicker'
                    name='effictive_period_start'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Effective Period End'
                    type='datePicker'
                    name='effictive_period_end'
                    required
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Reason'
                    type='textarea'
                    name='reason'
                    required
                />
            </Col>  
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Adherence'
                    type='checkbox'
                    name='adherence'
                    required
                />
            </Col>
        </Row> 
    );
};

export default MedicationForm;
