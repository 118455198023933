import { columnsInterface } from "../../../models/table-columns";
import { Button } from "antd";

export const patientColumns: columnsInterface[] = [
  { type: "string", title: "Patient", dataSelector: "patient" },
  { type: "string", title: "Practitioner", dataSelector: "practitioner" },
  { type: "date", title: "Request Date", dataSelector: "request_date" },
  { type: "string", title: "Biologic", dataSelector: "biologic" },
  { type: "string", title: "Outcome", dataSelector: "outcome" },
  { type: "string", title: "Request Type", dataSelector: "request_type" },
  { type: "string", title: "Status", dataSelector: "status" },
  { type: "date", title: "Submitted Date", dataSelector: "submitted_date" },
];
