import { FormattedMessage } from "react-intl";
import BasicModal from "../../../../../components/modal";
import { Button, Col, Row } from "antd"; 

interface ConfirmFormProps { 
    formVisable: boolean;
    setFormVisable: (state: boolean) => void; 
    onConfirmHandle?: () => Promise<any>;
    loadingConfirm?: boolean;
    data?: any;
}

const ModalConfirm: React.FC<ConfirmFormProps> = ({ 
    formVisable,
    setFormVisable,
    onConfirmHandle,
    loadingConfirm,
    data
}) => { 

    return(    
        <BasicModal
            header={<> Are You Sure? </>}
            headerType='normal'
            content={
                <> 
                    <div className="confirm-contant">  
                        {data && data.some((card: any) => card.list[0]?.value === "N/A") && (
                            <Row justify="center" className="font-bold"> Missing Attributes: </Row>
                        )}
                        {  
                            data && data.map((card: any, index: number) => {
                                return(
                                    <>
                                        {card.list[0]?.value === "N/A" && <Row justify="center"> - {card.title} </Row>}
                                    </>
                                )    
                            })
                        } 
                    </div>
                    <Row>
                        <Col span={8} offset={4}>
                            <Button
                                type='primary'
                                className='savebtn'
                                onClick={onConfirmHandle}
                                loading={loadingConfirm}
                            >
                                <FormattedMessage id={'Yes'} />
                            </Button>
                        </Col>
                        <Col span={8} offset={4}>
                            <Button
                                type='default'
                                className='savebtn'
                                onClick={() => {
                                    setFormVisable(false);
                                }}
                            >
                                <FormattedMessage id={'No'} />
                            </Button>
                        </Col>
                    </Row>
                </>
            }
            isOpen={formVisable}
            footer={false}
            width='30vw'
            handleCancel={() => {
                setFormVisable(false);
            }}
        />
    );
}
export default ModalConfirm;