import { useEffect } from "react";
import { useQueryClient } from "react-query";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router";

export default function Logout() {

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const logout = async () => {
        await Cookies.remove('CPI_TOKEN');
        await queryClient.clear();
        navigate("/login");
    }

    useEffect(() => {
        logout();
    }, [])

    return (<></>);
}