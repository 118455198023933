import { Button, Card, Col, Row } from "antd";
import './assesment.scss'
import { AsthmaTypes, ControlType } from "../../../../../constants/enums";
import { dataChart, mapEnumToSelectValues, optionsChart } from "../../../../../lib/utils";
import FormElement from "../../../../../components/form-element";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import assessment from "../../../../../lib/api/assessment";
import { notify } from "../../../../../components/notification";
import { IFormStep } from "../../../../../models/form-step";
import { Chart } from "react-google-charts";
import _ from "lodash";
import LableColor from "../../../../../components/lable-color";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "../../../../../atoms/atoms";
import ModalObservation from "./modal-observation";
import { useState } from "react";
import { Plus } from "react-feather";
import colors from "../../../../../constants/colors";
import missingIocn from '../../../../../assets/icons/patient-table/assesment/missing.svg';
import ModalConfirm from "./modal-confirm";
import moment from "moment";
import { dateFormatMonth } from "../../../../../constants";

const StepOne = ({ data, assessmentId }: any) => {
    const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(refetchMedicationDataAtom); 
    const [formVisable, setFormVisable] = useState<boolean>(false);
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false); 
    const [cardInfo, setCardInfo] = useState<number>();
    const queryClient = useQueryClient();

    const { control, reset, handleSubmit, setValue } = useForm<IFormStep>({
        mode: "onBlur",
        defaultValues: {
            asthma_type: data?.asthma_type ? data.asthma_type?.value : data?.suggested_asthma_type?.value,
            control_category: data?.control_category ? data.control_category?.value : data?.suggested_control_category?.value,
        },
        shouldFocusError: true,
    });

    const sharedProps = {
        control
    };

    const ConfirmMutate = (data: IFormStep) => {
        Confirm.mutate({
            ...data,
            id: assessmentId,
            step: 1,
        });
    }

    const OnSubmitResetValue = () => {
        setValue?.('asthma_type', data?.suggested_asthma_type?.value);  
        setValue?.('control_category', data?.suggested_control_category?.value);  
    }

    const Confirm = useMutation(assessment.updateStepAssessment, {
        onSuccess: (data) => {
            setConfirmVisible(false);
            setRefetchMedicationData(!refetchMedicationData);
            notify(
                'success',
                'ok',
                'Success',
                'Update Step One',
            ); 
            queryClient.invalidateQueries(['getClarityNote']);  
        }
    });


    return (
        <div className="one-step">
            <ModalObservation 
                setFormVisable={setFormVisable}
                formVisable={formVisable}
                cardInfo={cardInfo}
            /> {/* modal create observation after click on card */}
            <ModalConfirm  
                formVisable={confirmVisible} 
                setFormVisable={setConfirmVisible}
                onConfirmHandle={handleSubmit(ConfirmMutate)}
                data={data?.cards}
            />
            <Row>
                <Col flex={3}>
                    <Row> <p className="one-step-header-left">Medical Attributes</p> </Row>
                </Col>
                <Col flex={0.1}>
                    <div className="one-step-header-right">
                        <Row justify='space-between'>
                            {LableColor('Well', '#00B050')}
                            {LableColor('Alert', '#EA2323')}
                            {LableColor('Worse', '#FFFF99')}
                            {LableColor('Empty', '#A6A6A6')}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="row-card-asess">
                {
                    data?.cards?.map((card: any, index: number) => {
                        return (
                            <Col span={6} key={index} className={`${(index + 1) % (24 / 6) != 0 ? 'card-asess-border' : 'col-card-asess'}`}>
                                <Card
                                    title={ 
                                        <>
                                            <Row justify="space-between">
                                                <Col> {card.title} </Col> 
                                                <Col > <Plus className='pr-1' color={colors.primaryColor} size="25" /> </Col>
                                            </Row>
                                            {card.display_all === "yes" && 
                                                <Row justify="space-between">
                                                    <Col style={{ fontWeight: "normal", color: colors.primaryColor }}> 
                                                        {!_.isEmpty(card.list) && card.list[0].value + " " + card.unit + ", " + moment(card.list[0].date).format(dateFormatMonth)}  
                                                    </Col>  
                                                </Row>
                                            }
                                        </>
                                    }
                                    size={"small"}
                                    className={`card-asess ${index < (data?.cards?.length - (24 / 6)) ? 'card-asess-border-down' : ''}`}
                                    onClick={() => {
                                        setFormVisable(true)
                                        setCardInfo(card);
                                    }}
                                >
                                    {
                                        card.display_all === "no" ?
                                            (
                                                <p className="card-body-text" style={{ whiteSpace: 'pre', color: colors.black }}>
                                                    {card.list[0]?.value !== "N/A" ? (card.list[0] ? JSON.stringify(card.list[0]?.value).replace(/,/g, '\n').replace(/[{}"]/g, '') : "" + " " + card.unit)
                                                        : <img className='icon-h' src={missingIocn} />
                                                    }
                                                </p>
                                            )
                                            : (
                                                <Chart
                                                    chartType={card.graph == "line" ? "LineChart" : "ColumnChart"}
                                                    className="card-body-chart"
                                                    height="85px"
                                                    width="100%"
                                                    options={optionsChart(card)}
                                                    data={dataChart(card)}
                                                />
                                            )
                                    }
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className="row-asses-f">
                <Col span={7}>
                    <FormElement
                        {...sharedProps}
                        // value={data?.asthma_type !== 0 ? data?.asthma_type : data?.suggested_asthma_type}
                        options={mapEnumToSelectValues(AsthmaTypes)}
                        // className="full-width"
                        placeholder="Select Type"
                        label=''
                        type='select'
                        name='asthma_type'
                        required
                    />
                </Col>
                <Col span={7}>
                    <FormElement
                        {...sharedProps}
                        // value={data?.asthma_type !== 0 ? data?.asthma_type : data?.suggested_asthma_type}
                        options={mapEnumToSelectValues(ControlType)}
                        // className="full-width"
                        placeholder="Select Contol Category"
                        label=''
                        type='select'
                        name='control_category'
                        required
                    />
                </Col>
                <Col span={4}>
                    <Button
                        className="full-width"
                        onClick={()=>{
                            OnSubmitResetValue();
                        }}
                    >
                        Reset to default
                    </Button>
                </Col>
                <Col span={6}>
                    <Button
                        className="full-width"
                        type="primary"
                        onClick={()=>{
                            setConfirmVisible(true);
                        }}
                    >
                        Confirm Severity
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
export default StepOne;