import { IInputsPattern } from "../models/input-pattern";
export const inputsPatterns: IInputsPattern = {
  invalid: { value: "", msgKey: "invalidMsg" },
  userName: {
    value: /^[A-Za-z][A-Za-z0-9]*$/,
    msgKey: "userNamePatterMsg",
  },
  name: {
    value:
      /^[\u0621-\u064A\u0660-\u0669A-Za-z0-9_ ][\u0621-\u064A\u0660-\u0669A-Za-z0-9_ ]*$/,
    msgKey: "namePatterMsg",
  },
  password: {
    value: /^[a-zA-Z0-9]+$/,
    msgKey: "passwordPatterMsg",
  },
  phoneNumber: {
    value: /^09[0-9]{8}$/,
    msgKey: "phoneNumberPatterMsg",
  },
  landline: {
    value: /^[0-9]{0,10}$/,
    msgKey: "landlinePatterMsg",
  },
  nationalNumber: {
    value: /^\d{11}$/,
    msgKey: "nationalNumberInfoPatterMsg",
  },
  address: {
    value: /^[\u0621-\u064A\u0660-\u0669A-Za-z0-9_ ]{8,100}$/,
    msgKey: "addressPatterMsg",
  },
  militaryNumber: {
    value: /^\d{7}$/,
    msgKey: "militaryNumberPatterMsg",
  },
};
