const colors = {
  primaryColor: "#179c8b",
  secondrycolor: "#3B87FB",
  hovercolor: "#4ed6c9",
  white: "#ffffff",
  successColor: "#81ba00",
  warningColor: "#ffbc58",
  dangerColor: "#ea5455",
  lightColor: "#f8f8f9",
  semiDark: "#aaaaaa",
  lightSemiGray: "#eff0f1",
  lightGray: "rgb(244,247,248)",
  darkGray: "#898989",
  darkColor: "#2a3142",
  gray60: "#999999",
  transparentColor: "transparent",
  authBgColor: "#fafafa",
  light: "#f6f6f6",
  lightText: "#999",
  primaryExtraLight: "#c1ecea",
  blueGray: "#969FB7",
  black: "#232528",
  orange: "#ffc000",
  green: "#2cba00",
  semiGreen: "#a3ff00",
  yellow: "#fff400",
  semiOrange: "#F8AF4133",
  blue: "#00a8ff",
  semiBlue: "#36A2EB33",
  purple: "#800080",
  semiPurple: "#D8BFD8",
};

export default colors;
