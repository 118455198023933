import SideBar from "../../components/sidebar";
import { sideList } from "../../constants/icon-apps";
import Footer from "../../components/footer";
import { Layout, Menu } from 'antd';
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";

interface PageLayoutProps {
    children: JSX.Element;
}

const PageLayout: FunctionComponent<PageLayoutProps> = (props) => {
    const navigate = useNavigate();
    const { Content } = Layout;

    return (
        <Layout>
            {/*{cookies.Access_TOKEN &&*/}
            <SideBar
                MenuItems={sideList}
                onChange={(link) => navigate(link)}
                collapsed={true}
            />

            {/*}*/}
            <Layout>
                <Content>
                    {props.children}
                </Content>
                <Footer />
            </Layout>
        </Layout>
    );
};

export default PageLayout;
