import { currentIndexStepPaAtom, paAtom } from "../../../atoms/atoms"; 
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import ActionRight from "./components/action-right"; 
import LoaderApp from "../../../components/loader";
import ActionStep from "./components/action-step";
import GuidPanel from "./components/guid-panel";
import TopRight from "./components/top-right";
import { useParams } from "react-router-dom";
import TopLeft from "./components/top-left";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import pa from "../../../lib/api/pa";
import { Col, Row } from "antd"; 
import './index.scss'

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

type RouteParams= {
    id: string;
    biologic: any;
}

export default function PaDashboardPage() {
    const { id, biologic } = useParams<RouteParams>();
    
    const [paData, setPaAtom] = useRecoilState(paAtom);
    const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexStepPaAtom);     
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const paInfo = useQuery(
        ['pa_information', currentIndex, paData],
        () =>
            pa.getPaPaInformation({ id: id }),
        { 
            enabled: !!id
        },
    );

    const getPa = useQuery(['pa', currentIndex], () => pa.Get({ id: id }),
        {
            onSuccess(data) {
                // if(!id) setCurrentIndex(data.status);
                setPaAtom(data);
            },
            enabled: !!id
        },
    );  

    return (
        <LoaderApp loading={paInfo.isLoading || getPa.isLoading} >

            <Row gutter={[16, 16]} className="row-section" justify="center" align="middle">
                <Col span={18} >
                    <TopLeft data={paInfo?.data} />
                </Col>
                <Col span={6}>
                    <TopRight data={paInfo?.data?.flags} />
                </Col>
            </Row>

            <Row gutter={[16, 16]} className="row-section" justify="center" align="middle">
                <Col span={18}>
                    <ActionStep paId={Number(id)} />
                </Col>
                <Col span={6}>
                    <ActionRight data={paInfo?.data?.actions} />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={18}> 
                    {paInfo.data && paData.attachment && (currentIndex > 0) && 
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                            <div
                                style={{
                                height: "750px",
                                // maxWidth: "900px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                }}
                            >
                                <Viewer
                                fileUrl={paData.attachment.url}
                                plugins={[defaultLayoutPluginInstance]}
                                />
                            </div>
                        </Worker> }
                </Col>
                <Col span={6}>
                    <GuidPanel guideData={paInfo?.data} />
                </Col>
            </Row>
        </LoaderApp>
    )
}
