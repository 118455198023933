import React from 'react'; 
import { Col, Row } from 'antd';
import { useQuery } from 'react-query'; 
import { IFormProps } from '../../../models/form-props';
import FormElement from '../../../components/form-element';
import { mapEnumToSelectValues } from '../../../lib/utils';
import { UserTypes } from '../../../constants/enums';

interface UserFormProps extends IFormProps { }

const UserForm: React.FC<UserFormProps> = ({
    control,
    disabled,
    type
}) => { 
    
    const sharedProps = {
        control,
        disabled,
    };

    return ( 
        <Row>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='User Name'
                    type='input'
                    name='username'
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Email'
                    type='input'
                    name='email' 
                    otherFormProps={{ type: 'email' }}
                />
            </Col>
            {type =="Create" &&<>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Password'
                    type='input'
                    name='password1'
                    otherFormProps={{ type: 'password' }}
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Confirm Password'
                    type='input'
                    name='password2'
                    otherFormProps={{ type: 'password' }}
                    required
                />
            </Col>
            </>}
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='First Name'
                    type='input'
                    name='first_name' 
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Last Name'
                    type='input'
                    name='last_name' 
                />
            </Col>  
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Roles'
                    type='multiSelect'
                    name='roles' 
                    options={mapEnumToSelectValues(UserTypes)}
                    required
                />
            </Col>  
        </Row> 
    );
};

export default UserForm;
