import { Col, Row } from 'antd';
import PatientInfo from './components/patient-info';
import SidePage from './components/side';
import BodyPage from './components/body'; 
import _ from 'lodash';
import './index.scss';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import assessment from '../../lib/api/assessment';
import { useRecoilState } from 'recoil';
import { currentIndexStepAtom } from '../../atoms/atoms';

const Assessment = () => {
 
    const [_c, setCurrentIndex] = useRecoilState(currentIndexStepAtom);
    const { id: assessmentId } = useParams();
    
    const getAssessment = useQuery(['assessment step',assessmentId], 
        () => assessment.Get({ id: Number(assessmentId) }),
        {
            enabled: !!assessmentId, 
            onSuccess(data) {
                setCurrentIndex(data?.current_step - 1)
            },
        },
    );
    
    const getClarityNote  = useQuery(
        ['getClarityNote',assessmentId], 
        () => assessment.getClarityNote({ id: Number(assessmentId) }),
        {
            enabled: !!assessmentId,  
        },
    );

    return (
        <>
            <PatientInfo getAssessment={getAssessment} getClarityNote={getClarityNote} />

            <Row justify='space-between' className='b-s-page'>
                <Col span={18}>
                    <BodyPage />
                </Col>
                <Col span={6}>
                    <SidePage getClarityNote={getClarityNote}/>
                </Col>
            </Row>
        </>
    )
};

export default Assessment;