import { useState } from "react";
import GeneralFormContainer, { IFormError } from "../../../../../components/general-form-containner";
import BasicModal from "../../../../../components/modal";
import ObservationForm from "../observation/forms";
import { FormattedMessage } from "react-intl";
import observation from "../../../../../lib/api/observation";
import { notify } from "../../../../../components/notification";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { mapApiErrorToForm } from "../../../../../lib/utils";

const ModalObservation = ({formVisable,setFormVisable,cardInfo}:any) => {
    const { patientId, id: assessment_id } = useParams(); 
    const queryClient = useQueryClient();

    const toggleForm = () => {
        setFormVisable(false)
    }; 
    
    const onSubmit = useMutation(observation.Create, {
        onSuccess: () => { 
            notify(
                'success',
                'ok',
                'Success', 
            );
            toggleForm();
            queryClient.invalidateQueries(['assessmentStep']);
        }, 
    });
 
    const formContent = () => (
        <GeneralFormContainer
            dataName="Observation"
            type="Create"
            content={ObservationForm}
            actionLoading={ onSubmit.isLoading  } 
            toggleContent={toggleForm} 
            onSubmit={(data: any) => {
                onSubmit.mutate({
                    ...data,
                    patient: patientId,
                    assessment_id: assessment_id
                });
            }} 
            defaultValues={{
                category: cardInfo?.category,
                code: cardInfo?.code
            }}
        />
    );
    
    return (
        <BasicModal
            header={
                <h2 className='text-primary'>
                    <FormattedMessage id="Create" />{' '}
                    <FormattedMessage id="Observation" />
                </h2>
            }
            isOpen={formVisable}
            closable
            handleCancel={toggleForm}
            afterClose={toggleForm}
            width={'60vw'}
            footer={false}
            content={formContent()}
        />
    );
}
export default ModalObservation;