import React from 'react'; 
import { Col, Row } from 'antd'; 
import { IFormProps } from '../../../../models/form-props';
import FormElement from '../../../../components/form-element'; 
import { mapEnumToSelectValues } from '../../../../lib/utils';
import { PayerTypes } from '../../../../constants/enums'; 
import { useParams } from 'react-router-dom';
import PlanPage from '../../plan';

interface PayerFormProps extends IFormProps { }

const PayerForm: React.FC<PayerFormProps> = ({
    control,
    disabled,
    type
}) => { 
    const { id } = useParams();
    
    const sharedProps = {
        control,
        disabled,
    };

    return ( 
        <Row>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Name'
                    type='input'
                    name='name'
                    required
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Contact Number'
                    type='input'
                    name='contact_number'
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='State'
                    type='input'
                    name='state'
                    max={2}
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Type'
                    type='select'
                    name='type' 
                    options={mapEnumToSelectValues(PayerTypes)} 
                />
            </Col>  
        </Row> 
    );
};

export default PayerForm;
