
import {Col, Row } from "antd"; 
import { FunctionComponent } from "react";
import PatientCard from "../../../../components/patient-info/card-info";

interface ITopLeftProps {
    data?: any;
}

const TopLeft: FunctionComponent<ITopLeftProps> = ({ data }) => {

    const defultSummary = `Here are a few coverage tips to consider: 
    • Prior Authorization requirements may vary. Certain drugs may not be covered by certain member plans. 
    • Refer to the member’s individual policy for inclusion in the PA program and medication guide for determination of coverage. 
    • Member cost share may be higher for self-administered specialty drugs not obtained at an in-network specialty pharmacy. 
    • For provider administered drugs that are buy and bill, for office or outpatient facility settings, submit requests through Availity®. 
    • For prescriptions sent to CVS Specialty, CVS Specialty will coordinate prior authorization reviews using the CVS Specialty Fax Form. 
    • CoverMyMeds is preferred for electronic Prior Authorization (ePA) requests through– a free ePA service that allows prescribers to submit and check the status of a PA request electronically. Alternatively, standard fax authorization forms are also available. 
    • If you have questions or need further assistance, please fill free to ask the AI assistant.`

    return (
        <Row gutter={[8, 8]} align="middle" justify="center" className="section">
            <Col span={12}>
                <PatientCard
                    patient_gender={data?.patient_gender}
                    patient_name={data?.patient_name}
                    patient_age={data?.patient_age}
                    patient_dob={data?.patient_dob}
                    patient_id={data?.patient_id}
                    weight={data?.weight}
                />
            </Col> 
            <Col span={12} className="summary-section">
                <p className="font-bold">Summary :</p>
                {data?.summary?.value ?? defultSummary}
            </Col>
        </Row>
    );

}

export default TopLeft;