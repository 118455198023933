import config from "./config";
import ApiService from "./baseApi/api-service";
class Patient extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Patient */
  /* @name PatientGetAll */
  /* @request GET:/api/services/app/Patient/GetAll */
  public getAllPatients = (params: {
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Patient/GetAll", {
      params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Patient/Create */
  public patientCreate = (params: any): Promise<any> => {
    return this.post("/Patient/Create", {
      ...params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Patient/Delete */
  public patientDelete = (params: { patientId: number }): Promise<any> => {
    return this.delete(`/Patient/Delete`, {
      params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Patient/Delete */
  public getPatientPaInformation = (params: {
    patientId: any;
  }): Promise<any> => {
    return this.get(`/Patient/pa_information/${params?.patientId}/`);
  };
}
const patient = new Patient();
export default patient;
