import React from 'react';
import MainRouter from './routers/main';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { IntlProvider } from 'react-intl';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <IntlProvider locale='en' messages={{}}>
          <RecoilRoot>
            <ToastContainer />
            <MainRouter />
          </RecoilRoot>
        </IntlProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
