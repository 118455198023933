import GeneralTable from '../../../components/general-table';
import DetailsIcon from '../../../assets/icons/patient-table/Details.svg';
import { payerColumns } from './data';
import payer from "../../../lib/api/payer";
import { PayerTypes } from '../../../constants/enums';
import PayerForm from './forms';
import { useNavigate } from 'react-router-dom';

export default function PayersPage() {
    const navigate = useNavigate();

    return (
        <GeneralTable
            headerTable="Payers"
            dataName="Payer" 
            getAllFunc={payer.getAll}
            getFunc={payer.Get}
            delete
            deleteFunc={payer.Delete}
            create
            createFunc={payer.Create} 
            updateFunc={payer.Update} 
            formContent={PayerForm} 
            tableColumns={payerColumns}
            customOnRowClick={(row) => { 
                navigate(`/auth/payer/${row?.id}`);
            }} 
            tableDataMapper={(rows) => {
                return rows?.map((row: any, index: number) => {
                    return {
                        ...row,
                        type: PayerTypes[row?.type]
                    };
                });
            }}
            customActions={[
                {
                    Icon: <img src={DetailsIcon} className='icon-button-table' />,
                    cb: (row) => { 
                        navigate(`/auth/payer/${row?.id}`);
                    },
                    tooltip: 'Payer Dashboard',
                },
            ]} 
        />
    )
}
