import config from "./config";
import ApiService from "./baseApi/api-service";
class Account extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }

  /* @tags Account */
    /* @name UserGet */
    /* @request GET:/api/services/app/Account/Get */
    public Get = (params: { id?: number }): Promise<any> => {
      return this.get('/Account/Get', {
          params,
      });
  };

  /* @tags Account */
  /* @name UserGetAll */
  /* @request GET:/api/services/app/Account/GetAll */
  public getAll = (params: { 
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Account/GetAll", {
      params,
    });
  }; 
  
  /* @tags Account */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Account/Create */
  public Create = (params: { 
  }): Promise<any> => { 
    return this.post("/Account/Register/", {
      ...params,
    });
  };

  /* @tags Account */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Account/Update */
  public Update = (params: {
    id?: number;
  }): Promise<any> => {
    return this.put(`/Account/Update`, {
      ...params,
    });
  };

  /* @tags Account */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Account/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Account/Delete`,{params});
  };
}
const user = new Account();
export default user;
