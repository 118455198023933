import Swal from "sweetalert2";
import moment from "moment";
import { ValueLabelDto } from "../models/value_label";
import { ApiErrorDto, IFormError } from "../models/api-error";
import _ from "lodash";
import { dateFormatMonth } from "../constants";

export function showDeleteDialog(item: any, onSuccess: Function) {
  Swal.fire({
    title: "Do you want to delete this item?",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Delete",
    denyButtonText: `Cancel`,
  }).then((result) => {
    if (result["value"]) {
      onSuccess();
    }
  });
}

export function toast(text: string) {
  if (text == null || text.length == 0) return;

  Swal.fire({
    position: "top-end",
    icon: "success",
    title: text,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function toastError(text: string) {
  Swal.fire({
    position: "top-end",
    icon: "error",
    title: text ?? "",
    showConfirmButton: false,
    timer: 1500,
  });
}

export function formatDate(date: Date, format: any) {
  let d1 = moment.utc(date);
  if (!d1.isValid()) return "";
  return d1.format(format);
}

/**
 * Converts a file to a base64 encoded string.
 * @param  {File} file
 * @returns {Promise}
 */

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * Sets the document title
 * @param  {string} title
 */
export const setDocumnetTitle = (title: string) => (document.title = title);

type EnumType = { [s: number]: string };
/**
 * @description Maps an Enum to an object
 * @param  {EnumType} enumerable Enum to map
 * @param  {Function} fn The map function
 * @returns Object where the key is the number and the value is the string
 */
export function mapEnum(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map(
    (key: any) => enumerable[key]
  );
  // we are only interested in the numeric identifiers as these represent the values
  let enumValues: number[] = enumMembers.filter((v) => typeof v === "number");

  // now map through the enum values
  return enumValues.map((m, i) => fn({ [m]: enumerable[m] }, i));
}

export const mapEnumToSelectValues = (
  enumerable: EnumType
): ValueLabelDto[] => {
  const newOptions: ValueLabelDto[] = [];
  mapEnum(enumerable, (value: any) => {
    newOptions.push({
      value: Number(Object.keys(value)[0]),
      label: String(Object.values(value)[0]),
    });
  });
  return newOptions;
};

/**
 * Maps api validation errors to react-hook-form errors
 * @param  {ApiErrorDto} Api error response
 * @returns IFormError[]
 */
export const mapApiErrorToForm = (errorResponse: ApiErrorDto): IFormError[] => {
  if (_.isEmpty(errorResponse?.error?.validationErrors)) return [];
  const validationErrors: IFormError[] = [];
  errorResponse.error.validationErrors.forEach((validationError: any) => {
    validationErrors.push({
      path: validationError.members[0],
      message: validationError.message,
    });
  });

  return validationErrors;
};

export const getAge = (birth_date: any): any => {
  return Math.floor((Date.now() - new Date(birth_date).getTime()) / 3.15576e10);
};

export const optionsChart = (card: any) => {
  return {
    backgroundColor: "#EFEFEF",
    legend: "none",
    pointSize: 7,
    hAxis: {
      title: "Month",
    },
    // vAxis: {
    //   title: "Value",
    // },
    animation: {
      startup: true,
      duration: 500,
    },
    chartArea: {
      left: "8%",
      right: "8%",
      top: "8%",
      width: "80%",
      height: "70%",
    },
  };
};

export const dataChart = (card: any) => {
  let newData = card.list?.map((item: any) => [
    new Date(item.date), //col month 
    item.value, //row value
    item.status?.color, //color
    `date: ${moment(item.date).format(dateFormatMonth)} \n value: ${item.label ?? item.value}`, //display on hover 
  ]);

  newData = !_.isEmpty(newData) ? [...newData] : [[0, 0, "#fff", "month"]];

  return [
    ["month", "value", { role: "style" }, { role: "tooltip" }],
    ...newData,
  ];
};

export const attachmentMapper = (
  attachmentsNamesArr: string[],
  data: any,
  multiple?: boolean,
): Object => {
  let result: any = {};

  const pickedAttachments = _.pick(data, attachmentsNamesArr);

  _.forIn(pickedAttachments, (value, key) => {    
      let Ids = value && Array.isArray(value) ? value?.map((v: any) => v.response?.data?.id ?? v?.id) : [value?.id]; 
      result[`${key}_id${multiple ? 's' : ''}`] = multiple
          ? Ids
          : _.first(Ids);
  });

  return result;
};