import GeneralTable from '../../components/general-table';
import { userColumns } from './data';
import user from "../../lib/api/user";
import { UserTypes } from '../../constants/enums';
import UserForm from './forms';

export default function UsersPage() {

    return (
        <GeneralTable
            headerTable="Users"
            dataName="User"
            getAllFunc={user.getAll}
            getFunc={user.Get} 
            delete
            deleteFunc={user.Delete}
            create
            createFunc={user.Create}
            update
            updateFunc={user.Update}
            formContent={UserForm} 
            tableColumns={userColumns}
            tableDataMapper={(rows) => {
                return rows?.map((row: any, index: number) => {
                    return {
                        ...row,
                        roles: row?.roles.map((role: number) => UserTypes[role]).join(', ')
                    };
                });
            }} 
        />
    )
}
