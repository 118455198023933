// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.squaer {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 3px;
}

.squaer-text {
  font-size: 11px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/styles/lable-color/index.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,iBAAA;AAAJ","sourcesContent":["\n.squaer {\n    width: 15px; \n    height: 15px;\n    border-radius: 10px;\n    margin-right: 3px;\n}\n\n.squaer-text {\n    font-size: 11px;\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
