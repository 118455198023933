
import { useParams } from "react-router-dom";
import Assessment from "./assesment";
import { Button, Col, Menu, MenuProps, Row } from 'antd';
import { useState } from "react";
import Condition from "./condition";
import Medication from "./medication";
import Observation from "./observation";
import AssesmentModal from "../../../patients/assesment-modal";

const BodyPage = () => {
    const [currentItem, setCurrentItem] = useState('assessment');
    const { patientId ,id: assessmentId } = useParams();
    const [isOpenAssesment, setIsOpenAssesment] = useState(false);
    
    const items: MenuProps['items'] = [
        {
            label: 'Assessment',
            key: 'assessment',
            onClick: ()=> setCurrentItem('assessment')         
        },
        {
            label: 'Observation',
            key: 'observation',
            onClick: ()=> setCurrentItem('observation')         
        },
        {
            label: 'Condition',
            key: 'condition',
            onClick: ()=> setCurrentItem('condition')         
        },
        {
            label: 'Medication',
            key: 'medication',
            onClick: ()=> setCurrentItem('medication')         
        },
    ]

    const currentInternal = (index: string) => {
        switch (index) {
            case 'assessment':
                return <Assessment assessmentId={assessmentId} />;
            case 'condition':
                return <Condition />;
            case 'medication':
                return <Medication />; 
            case 'observation':
                return <Observation />; 
            default:
                return <Assessment assessmentId={assessmentId} />;
        }
    }

    return (
        <div className="body-page">
            <Row justify='space-between' align='middle'>
                <Col span={20}>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        className='ant-menu-asses'
                        defaultValue='assessment'
                        defaultSelectedKeys={['assessment']} 
                        items={items}
                    />
                </Col>
                <Col>
                    <Button
                        className="full-width"
                        type="primary"
                        onClick={()=>{
                            setIsOpenAssesment(true)
                        }}
                    >
                        Show Assessment
                    </Button>
                </Col>
            </Row>
            {
                isOpenAssesment && <AssesmentModal
                    setIsOpenAssesment={setIsOpenAssesment}
                    isOpenAssesment={isOpenAssesment}
                    patientId={Number(patientId)}
                />
            }
            
            {currentInternal(currentItem)}
        </div>
    );
}
export default BodyPage;