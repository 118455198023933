import '../../styles/form-element-wrapper/index.scss';
import { FunctionComponent } from 'react';
import { AnimationControls, motion } from 'framer-motion';
import {
    defaultTranstion,
    minBackgroundWidth,
} from '../../constants/framer';
import { FormattedMessage } from 'react-intl';

interface FromElemnetWrapperProps {
    label?: string;
    labelSide?: string;
    focusController: AnimationControls;
    errorController?: AnimationControls;
    required?: boolean;
    children?: JSX.Element
}

const FromElemnetWrapper: FunctionComponent<FromElemnetWrapperProps> = ({
    children,
    label,
    labelSide,
    focusController,
    errorController,
    required,
}) => {
    return (
        <div className='m-2'>
            {label && (
                <p className='form-element-label'>
                    {
                        labelSide && 
                        <FormattedMessage id={labelSide} />
                        
                    }
                    {
                        labelSide && 
                        <FormattedMessage id=" " />
                    }
                    
                    <FormattedMessage id={label} />
                    {required && (
                        <span className='form-element-required-star'>
                            *
                        </span>
                    )}
                </p>
            )}
            <motion.div className='form-element-container'>
                <div className='w-full'>
                    <motion.div
                        className='form-element-focus-background'
                        transition={defaultTranstion}
                        initial={minBackgroundWidth}
                        animate={focusController}
                    ></motion.div>
                    <motion.div
                        className='form-element-error-background'
                        transition={{ ...defaultTranstion, duration: 1 }}
                        initial={minBackgroundWidth}
                        animate={errorController}
                    ></motion.div>
                </div>
                {children}
            </motion.div>
        </div>
    );
};

export default FromElemnetWrapper;
