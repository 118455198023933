import { Spin } from 'antd';
import { FunctionComponent, ReactNode } from 'react';
import '../../styles/animated-logo/index.scss';
import { motion, Transition } from 'framer-motion';  
import { defaultTranstion } from '../../constants/framer';
import colors from '../../constants/colors';

interface LoaderAppProps {
    loading?: boolean,
    children?: ReactNode,
}

const LoaderApp: FunctionComponent<LoaderAppProps> = ({
    loading,
    children
}) => {

    const transition: Transition = {
        duration: 1,
        ease: 'easeInOut',
    };
    const color = colors.primaryColor;
    
    const AnimatedLoader = () => ( 
        <div className='relative loader-container'>
            <motion.h3
                transition={{ ...defaultTranstion, duration: 0.5 }}
                className='logo-text'
                style={{ color: color ?? colors.white }}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
            >
                <p>
                    Clarity Practice Intelligence
                </p>
            </motion.h3>
            <svg
                version='1.1'
                id='Layer_1'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                x='0px'
                y='0px'
                viewBox='0 0 167 45'
                xmlSpace='preserve'
                className='animated-logo'
            >
                <motion.path
                    className='st0'
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    whileHover={{ scale: 1.2 }}
                    transition={{
                        ...transition, 
                        repeat: Infinity,
                    }}
                    d='M22.9,26 158.8,26'
                    style={{ stroke: color ?? '#fff' }}
                /> 
            </svg>    
        </div> 
    )
    return ( 
        <Spin
            indicator={<AnimatedLoader />}
            spinning={loading}
        >
            {children}
        </Spin>
    );
};

export default LoaderApp;
