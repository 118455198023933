import { Drawer } from 'antd';
import React from 'react';
import { X } from 'react-feather';
// import '../../styles/sideform/index.scss';

type placementType = 'top' | 'right' | 'bottom' | 'left';
interface SideDrawerProps {
    visible: boolean;
    onClose: () => void;
    width?: string;
    placement?: placementType;
    header?: JSX.Element;
    children: React.ReactNode;
}
const SideDrawer: React.FC<SideDrawerProps> = ({
    visible,
    onClose,
    width,
    placement,
    children,
    header,
}) => {
    return (
        <>
            <Drawer
                destroyOnClose
                title={header}
                placement={placement ?? 'right'}
                closeIcon={
                    <div className='closeIcon'>
                        <X />
                    </div>
                }
                visible={visible}
                onClose={onClose}
                width={width ?? '50vw'}
            >
                {children}
            </Drawer>
        </>
    );
};
export default SideDrawer;
