import rightIcon from '../../../../assets/icons/patient-table/assesment/right.svg';
import leftIcon from '../../../../assets/icons/patient-table/assesment/left.svg';
import { currentIndexStepPaAtom, paAtom } from "../../../../atoms/atoms";
import { notify } from "../../../../components/notification";
import StepsDiv from "../../../../components/steps";
import PaStepThree from "./steps/step-three";
import PaStepFour from "./steps/step-four";
import { FunctionComponent } from "react";
import { useMutation } from "react-query";
import PaStepOne from "./steps/step-one";
import { Button, Col, Row } from "antd";
import { useRecoilState } from "recoil";
import pa from "../../../../lib/api/pa";

interface IActionStepProps {
    paId: number;
}

const ActionStep: FunctionComponent<IActionStepProps> = ({ paId }) => {

    const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexStepPaAtom);
    const paUpdateMutation = useMutation(pa.paUpdate, {
        onSuccess: () => {
            notify(
                'success',
                'ok',
                'Success',
                'PA generation',
            );
        }
    });

    const currentInternal = (index: number) => {
        switch (index) {
            case 0:
                return <PaStepOne paUpdateMutation={paUpdateMutation} paId={paId} />;
            case 2:
                return <PaStepThree paUpdateMutation={paUpdateMutation} paId={paId} />;
            case 3:
                return <PaStepFour paUpdateMutation={paUpdateMutation} paId={paId} />;
            case 4:
                return <PaStepFour paUpdateMutation={paUpdateMutation} paId={paId} disabled />;
            // default:
            //     return <StepFive data={data} assessmentId={assessmentId} />;
        }
    }

    return (
        <div className="section">
            <Row gutter={[8, 8]} justify="center" align="middle">
                <Col span={1}>
                    <Button
                        style={{ display: `${currentIndex <= 0 ? 'none' : ''}` }}
                        className="pa-btn-step"
                        icon={
                            <img
                                src={leftIcon}
                                onClick={() => {
                                    setCurrentIndex(currentIndex - 1)
                                    // setLoading(true);
                                }}
                            />
                        }
                    />
                </Col>
                <Col span={22}>
                    <StepsDiv
                        current={currentIndex}
                        items={[
                            {
                                title: 'Coverage',
                            },
                            {
                                title: 'Information',
                            },
                            {
                                title: 'Submision',
                            },
                            {
                                title: 'Response',
                            },
                        ]}
                        onStepClick = {
                            (current:number) => { 
                                setCurrentIndex(current);
                                // setLoading(true);
                            }
                        }
                    />
                </Col>
                <Col span={1}>
                    <Button
                        style={{ display: `${currentIndex > 2 ? 'none' : ''}` }}
                        className="pa-btn-step"
                        icon={
                            <img src={rightIcon}
                                onClick={() => {
                                    setCurrentIndex(currentIndex + 1)
                                    // setLoading(true);
                                }
                                }
                            />
                        }
                    />
                </Col>
            </Row>
            {currentInternal(currentIndex)}
        </div>
    );

}

export default ActionStep;