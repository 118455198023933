import { Col, Divider, Row } from 'antd'; 
import { IFormProps } from '../../../../models/form-props';
import FormElement from '../../../../components/form-element';
import { mapEnumToSelectValues } from '../../../../lib/utils';
import { BenefitType, BiologicTypes, PayerTypes, PlanType } from '../../../../constants/enums';
import _ from 'lodash';
import { Controller, useFieldArray } from 'react-hook-form';
import AntCollapse from '../../../../components/collapse';

interface PlanFormProps extends IFormProps { }

const PlanForm: React.FC<PlanFormProps> = ({
    control,
    disabled,
    setValue,
    type,
    data
}) => { 

    const sharedProps = {
        control,
        disabled,
    };
    // Use useFieldArray to handle dynamic fields
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'pa_forms', // The name must match the array structure in defaultValues
    });

    //render Children
    const renderChildren = (pa_form: any, index: number) => { 
        return (
            <> 
                {/* <Col span={12}>
                    <FormElement
                        {...sharedProps}
                        label='Name'
                        type='input'
                        name={`pa_forms[${index}].name`}   
                        disabled
                        required
                    />
                </Col>
                <Col span={12}>
                    <FormElement
                        {...sharedProps}
                        label='Biologic'
                        type='select'
                        name={`pa_forms[${index}].biologic`}  
                        options={mapEnumToSelectValues(BiologicTypes)}  
                        disabled
                        required
                    />
                </Col> */}
                <Col span={24}>
                    <FormElement
                        {...sharedProps}
                        label='Link'
                        type='input'
                        name={`pa_forms[${index}].link`}   
                    />
                </Col>
                <Col span={24}>
                    <FormElement
                        {...sharedProps}
                        label='Attachment'
                        type='uploadFile'
                        name={`pa_forms[${index}].attachment`}  
                    />
                </Col> 
            </>
        );
    };

    return ( 
        <Row>  
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Name'
                    type='input'
                    name='plan_name'  
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Number'
                    type='number'
                    name='plan_number' 
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Type'
                    type='select'
                    name='plan_type' 
                    options={mapEnumToSelectValues(PlanType)}  
                    required
                />
            </Col>
            <Col span={12}> 
                <FormElement
                    {...sharedProps}
                    label='Benefit Type'
                    type='select'
                    name='benefit_type' 
                    options={mapEnumToSelectValues(BenefitType)}  
                    required
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Part a'
                    type='checkbox'
                    name='part_a' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Part b'
                    type='checkbox'
                    name='part_b' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Part c'
                    type='checkbox'
                    name='part_c' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Part d'
                    type='checkbox'
                    name='part_d' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Medicare Rx'
                    type='checkbox'
                    name='medicare_rx' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Medicare Supplement'
                    type='checkbox'
                    name='medicare_supplement' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Managed'
                    type='checkbox'
                    name='managed' 
                />
            </Col>  
             {type === "Update" &&   
                    <Col span={24}>
                        <AntCollapse
                            accordion={true} 
                            items={
                                fields.map((pa_form:any, index) =>  ( {
                                    key: index,
                                    label: <strong>{pa_form?.name}</strong>,
                                    children: renderChildren(pa_form, index)
                                }
                                ))
                            }
                            defaultActiveKey={0}
                        /> 
                    </Col>
            }
        </Row> 
    );
};

export default PlanForm;
