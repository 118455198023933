import slide from '../assets/icons/sidebar/slide.svg';
import patients from '../assets/icons/sidebar/patient.svg';
import pa from '../assets/icons/sidebar/pa.svg';
import users from '../assets/icons/sidebar/users.svg';
import payers from '../assets/icons/sidebar/payer.svg'; 
import setting from '../assets/icons/sidebar/setting.svg';
import logout from '../assets/icons/sidebar/logout.svg';

export interface IIconsType {
    label: string;
    icon: any;
    link: string;
}

export const sideList: IIconsType[] = [
    {
        label: ' ',
        icon: slide,
        link: '/auth/dashboard'
    },
    {
        label: 'Patients',
        icon: patients,
        link: '/auth/patients'
    },
    {
        label: 'Prior Authorization',
        icon: pa,
        link: '/auth/pa'
    },
    {
        label: 'payers',
        icon: payers,
        link: '/auth/payers'
    },
    {
        label: 'Setting',
        icon: setting,
        link: '/auth/users'
    },
    {
        label: 'Logout',
        icon: logout,
        link: '/logout'
    },
];
