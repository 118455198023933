import { FunctionComponent } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router';
import { authenticatedRoutes } from '../authenticated-routes';
import Login from '../../pages/login';
import Logout from '../../pages/logout';
import AuthenticatedRoute from '../../components/authenticated-route'; 

interface MainRouterProps { }
export const routes: RouteObject[] = [
    {
        path: '/',
        element: <Navigate replace to='/auth/patients' />,
    },
    {
        path: 'auth',
        element: <AuthenticatedRoute />,
        children: authenticatedRoutes,
    },
    { path: 'login', element: <Login /> },
    // { path: 'register', element: <Register /> },
    { path: 'logout', element: <Logout /> },
    { path: '*', element: <h1>404</h1> },
];
const MainRouter: FunctionComponent<MainRouterProps> = () => {
    let element = useRoutes(routes);
    return element;
};

export default MainRouter;
