import { useParams } from "react-router-dom";
import GeneralTable from "../../../../../components/general-table";
import condition from "../../../../../lib/api/condition";
import { conditionColumns } from "./data";
import ConditionForm from "./forms";

const Condition = () => {
    const { patientId } = useParams();

    return (
        <GeneralTable
            dataName="Condition"
            getAllFunc={()=>condition.getAll({patient_id: patientId})}
            getFunc={condition.Get}
            delete
            deleteFunc={condition.Delete}
            create
            createFunc={condition.Create}
            update
            updateFunc={condition.Update}
            formContent={ConditionForm}   
            tableColumns={conditionColumns}   
            FormSubmitMapper={(data: any) => {
                return {
                    ...data,
                    code: data?.display?.value ?? data?.code,
                    display: data?.display?.label ?? data?.display,
                    patient: patientId
                };
            }} 
        />
    );
}
export default Condition;