import { columnsInterface } from "../../../../../../models/table-columns";

export const observationColumns: columnsInterface[] = [
  { type: "string", title: "Name", dataSelector: "display" }, 
  { type: "string", title: "Code", dataSelector: "code" },  
  { 
    type: "custom", 
    title: "Value", 
    dataSelector: "value",
    render(text,row) {
      return row.value + " " + row.unit
    }
  }, 
  { type: "date", title: "Date Issued", dataSelector: "issued_at" },  
];
