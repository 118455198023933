import FormElement from "../../../../components/form-element";
import AntCollapse from "../../../../components/collapse";
import { IFormStep } from "../../../../models/form-step";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";

interface IGuidPanelProps {
    guideData: any;
}

const GuidPanel: FunctionComponent<IGuidPanelProps> = ({ guideData }) => {

    const { control, reset, handleSubmit } = useForm<IFormStep>({
        mode: "onBlur",
        defaultValues: {},
        shouldFocusError: true,
    });

    const childrenPanel = (item: any) => (
        <div>
            {!_.isEmpty(item?.approval_criteria) && <p className="title"> Approval Criteria : </p>}
            {
                !_.isEmpty(item?.approval_criteria) && item.approval_criteria.map((item: any) => (
                    <>
                        <p className="title-ac"> - {item?.label} : </p>
                        {
                            item?.options?.map((item: any) => (
                                <FormElement
                                    type='checkbox'
                                    name={item?.label}
                                    control={control}
                                    label={item?.label}
                                    isChecked={item?.value}
                                    disabled
                                />
                            ))
                        }
                    </>
                ))
            }
            {
                !_.isEmpty(item.note) && item.note.map((item: any) => (
                    <>
                        <p className="title"> {item?.label} : </p>
                        <p> {item?.value} </p>
                    </>
                ))
            }
            {
                !_.isEmpty(item.missing_info) && item.missing_info.map((item: any) => (
                    <>
                        <p className="title-missing_info"> {item?.label} : </p>
                        <p> {item?.value} </p>
                    </>
                ))
            }
        </div>
    )

    return (
        <div>
            <div className="guid-panel-text"> Guide Panel </div>
            {
                <div className="item-panel">
                    <AntCollapse
                        accordion={true}
                        items={
                            guideData?.guide?.map((item: any, index: number) => ({
                                key: index,
                                label: <strong>{item?.section}</strong>,
                                children: childrenPanel(item)
                            }
                            ))
                        }
                    />
                </div>
            }
        </div>
    );

}

export default GuidPanel;