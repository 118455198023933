// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.st0 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.animated-logo {
  width: 135px;
  height: 45px;
}

.logo-text {
  color: white;
  position: absolute;
  top: 8%;
  margin-right: 0.5rem;
  font-family: "Noto Sans Arabic", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}

.loader-container {
  width: 15rem;
  text-align: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/animated-logo/index.scss","webpack://./src/styles/variables/fonts.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;EACA,qBAAA;AADJ;;AAIA;EACI,YAAA;EACA,YAAA;AADJ;;AAIA;EACI,YAAA;EACA,kBAAA;EACA,OAAA;EACA,oBAAA;EACA,2CCrBe;EDsBf,gBAAA;EACA,iBAAA;AADJ;;AAIA;EACI,YAAA;EACA,kBAAA;EACA,uBAAA;AADJ","sourcesContent":["@import '../variables/fonts.scss';\n\n.st0 {\n    fill: none;\n    stroke: #ffffff;\n    stroke-width: 5;\n    stroke-linecap: round;\n    stroke-linejoin: round;\n    stroke-miterlimit: 10;\n}\n\n.animated-logo {\n    width: 135px;\n    height: 45px;\n}\n\n.logo-text {\n    color: white;\n    position: absolute;\n    top: 8%;\n    margin-right: 0.5rem;\n    font-family: $main-arabic-font;\n    font-weight: 700;\n    font-size: 1.4rem;\n}\n\n.loader-container {\n    width: 15rem;\n    text-align: center;\n    justify-content: center;\n}\n  ","$main-arabic-font: 'Noto Sans Arabic', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
