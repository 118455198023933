import { Col, Row } from 'antd'; 
import { IFormProps } from '../../../../models/form-props';
import FormElement from '../../../../components/form-element'; 
import { BiologicTypes } from '../../../../constants/enums';
import { mapEnumToSelectValues } from '../../../../lib/utils';

interface DrugPolicyFormProps extends IFormProps { }

const DrugPolicyForm: React.FC<DrugPolicyFormProps> = ({
    control,
    disabled,
    setValue,
    data
}) => { 

    const sharedProps = {
        control,
        disabled,
    };
 
    return ( 
        <Row>  
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Name'
                    type='input'
                    name='name'  
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Biologic'
                    options={mapEnumToSelectValues(BiologicTypes)} 
                    type='select'
                    name='biologic'  
                    required
                />
            </Col>
            <Col span={24}>
                <FormElement
                    {...sharedProps}
                    label='Link'
                    type='input'
                    name='link'   
                />
            </Col>
            <Col span={24}>
                <FormElement
                    {...sharedProps}
                    label='Attachment'
                    type='uploadFile'
                    name='attachment'   
                />
            </Col> 
        </Row> 
    );
};

export default DrugPolicyForm;
