import { BiologicTypes, OutcomeChoices, RequestTypeEnum } from "../../../../../constants/enums";
import FormElement from "../../../../../components/form-element";
import { mapEnumToSelectValues } from "../../../../../lib/utils";
import { paAtom } from "../../../../../atoms/atoms";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { Button, Col, Row } from "antd";

interface IPaStepFourProps {
    paId: number;
    paUpdateMutation: any;
    disabled?: boolean;
}

const PaStepFour: FunctionComponent<IPaStepFourProps> = ({ paUpdateMutation, paId, disabled }) => {

    const [paData, _p] = useRecoilState(paAtom);
    const [outcome, setOutcome] = useState<number>(paData.outcome);
    const { control, reset, handleSubmit } = useForm<any>({
        mode: "onBlur",
        defaultValues: {
            ...paData
        },
        shouldFocusError: true,
    });

    const sharedProps = {
        control
    };

    useEffect(()=>{
        reset({ ...paData }); 
        setOutcome(paData.outcome);
    },[paData]);

    const handleConfirm = (data: any) => {
        paUpdateMutation.mutate({  
            outcome: data.outcome,
            denial_reason: data.denial_reason,
            effective_date: data.effective_date,
            expiration_date: data.expiration_date, 
            id: paId,
            status: 4
        });
    }

    return (
        <Row gutter={[8, 8]} align="middle" justify="center">
            <Col span={5}>
                <FormElement
                    {...sharedProps}
                    options={mapEnumToSelectValues(OutcomeChoices)}
                    placeholder="Outcome"
                    label=''
                    type='select'
                    name='outcome'
                    onChange={(e: number) => { setOutcome(e) }}
                    required
                    disabled={disabled}
                />
            </Col>
            {outcome == 1 && <Col span={10}>
                <FormElement
                    {...sharedProps}
                    placeholder="Denial Reason"
                    label=''
                    type='textarea'
                    name='denial_reason'
                    required
                    disabled={disabled}
                />
            </Col>}

            {outcome == 0 && <>
                <Col span={5}>
                    <FormElement
                        {...sharedProps}
                        placeholder="Effective Date"
                        label=''
                        type='datePicker'
                        name='effective_date'
                        required
                        disabled={disabled}
                    />
                </Col>
                <Col span={5}>
                    <FormElement
                        {...sharedProps}
                        placeholder="Expiration Date"
                        label=''
                        type='datePicker'
                        name='expiration_date'
                        required
                        disabled={disabled}
                    />
                </Col>
            </>}

            {!disabled && <Col span={2}>
                <Button
                    type="primary"
                    onClick={handleSubmit(handleConfirm)}
                    loading={paUpdateMutation.isLoading} 
                >
                    Confirm
                </Button>
            </Col>}
        </Row>
    );

}

export default PaStepFour;