import { FunctionComponent } from "react";
import moment from "moment";
import { Avatar, Card, Col, Row } from "antd";
import { dateFormatMonth } from '../../constants';
import { Gender } from '../../constants/enums';
import group2052Iocn from '../../assets/icons/patient-table/assesment/group2052.svg';
import usenameIocn from '../../assets/icons/patient-table/assesment/user-name.svg';
import birthdayIocn from '../../assets/icons/patient-table/assesment/birthday.svg';
import FemalePatient from '../../assets/icons/FemalePatient.svg';
import MalePatient from '../../assets/icons/MalePatient.svg'; 

interface IPatientCard {  
    patient_gender: number;
    patient_name: string;
    patient_age: number;
    patient_dob: Date;
    patient_id: number;
    weight?: number;
    loading?: boolean;
}

const PatientCard: FunctionComponent<IPatientCard>= ({
    patient_gender,
    patient_name,
    patient_age,
    patient_dob,
    patient_id,
    weight,
    loading
}) => { 

    return ( 
        <Card size={"small"} bordered={false} className='patient-info' loading={loading}>  
            <Row>
                <Col span={5}>
                    <Avatar 
                        className='antd-avatar' 
                        size={70} 
                        src={
                            patient_gender == 0
                                ? MalePatient
                                : patient_gender == 1
                                    ? FemalePatient : undefined
                        }
                    />
                </Col>  
                <Col span={19}>
                    <Row className="row-info">
                        <Col span={12}>
                            <p className='p-row-info'> <img className='icon-h' src={usenameIocn} /> {patient_name} </p>
                        </Col>
                        <Col span={12}> <p className='p-row-info'> {patient_age} Years ,  {Gender[patient_gender]} , {weight} Kg</p></Col>
                    </Row>
                    <Row className="row-info">
                        <Col span={12}>
                            <p className='p-row-info'>
                                <img className='icon-h' src={birthdayIocn} /> {moment(patient_dob)?.format(dateFormatMonth)}
                            </p>
                        </Col>
                        <Col span={12}>
                            <p className='p-row-info'>
                                <img className='icon-h' src={group2052Iocn} /> Id: {patient_id}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}> <p className='p-row-info'>Severe Asthma</p></Col>
                    </Row>
                </Col> 
            </Row>
        </Card>
    )

}

export default PatientCard;