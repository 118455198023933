import { currentIndexStepPaAtom, paAtom } from "../../../atoms/atoms";
import { dateFormatMonth } from "../../../constants";
import ActionRight from "./components/action-right";
import PsPdfKit from "../../../components/psPdfKit";
import LoaderApp from "../../../components/loader";
import ActionStep from "./components/action-step";
import GuidPanel from "./components/guid-panel";
import TopRight from "./components/top-right";
import { useParams } from "react-router-dom";
import TopLeft from "./components/top-left";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import pa from "../../../lib/api/pa";
import { Col, Row } from "antd";
import moment from "moment";
import './index.scss'

type RouteParams= {
    id: string;
    biologic: any;
}

export default function PaDashboardPage() {
   

    const { id, biologic } = useParams<RouteParams>();

    const [paData, _p] = useRecoilState(paAtom);
    const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexStepPaAtom);  
    
    const FindName = (name: string, arr: any) => (arr?.some((item: any) => item?.name.includes(name)));
    const FindLabel = (label: string, section: string, arr: any) => (arr?.find((item: any) => item?.label.includes(label) && item?.section.includes(section)));

    const patientData = useQuery(
        ['pa_information', currentIndex, paData],
        () =>
            pa.getPaPaInformation({ id: id }),
        { 
            enabled: !!id
        },
    );

    useQuery('pa',() => pa.Get({ id: id }),
        {
            onSuccess(data) {
                setCurrentIndex(data.status);
            },
            enabled: !!id
        },
    );


    function setFormFieldValue(formFields: any, instance: any) {
        // const parsedData = patientData?.data?.pdf.reduce((acc: any, item: any) => {
        //     acc[item.label] = item.value;
        //     return acc;
        // }, {});

        const pdfData = patientData.data?.pdf;
        const fieldValues = {
            // ...(FindName("Patient Info T.1", formFields) && { "Patient Info T.1": patientData?.data?.first_name }),
            // ...(FindName("Patient Info T.2", formFields) && { "Patient Info T.2": patientData?.data?.last_name }),
            // ...(FindName("Member Name first  last", formFields) && { "Member Name first  last": (patientData?.data?.patient_name) }),
            // ...(FindName("t1", formFields) && (biologic == "Tezspire") && { "t1": (patientData?.data?.first_name + " " + patientData?.data?.last_name) }),
            // ...(FindName("Check Box1t901", formFields) && { "Check Box1t901": [Gender[patientData?.data?.gender]] }),
            // // ...(FindName("Date of Birth", formFields) && { "Date of Birth": moment(patientData?.data?.dob)?.format(dateFormatMonth) }),
            // ...(FindName("IDC10 Code", formFields) && { "IDC10 Code": patientData?.data?.ilness[0].label }),
            // ...(FindName("Text13", formFields) && { "Text13": patientData?.data?.ilness[0].value }),
            // ...(FindName("Diagnosis T.1", formFields) && { "Diagnosis T.1": patientData?.data?.ilness[0].value }),
            // ...patientData?.data?.pdf.map((item: any, index: number) => ({
            //     [item.label]: item.value,
            // })), 
            ...(FindName("Member Name first  last", formFields) && { "Member Name first  last": FindLabel("Member Name (first & last)", "Member Information", pdfData)?.value }),
            ...(FindName("Date of Birth", formFields) && { "Date of Birth": moment(FindLabel("Date of Birth", "Member Information", pdfData)?.value)?.format(dateFormatMonth) }),
            // ...(FindName("Gender", formFields) && { "Check Box1t901": FindLabel("Check Box1t901", pdfData)?.value }),
            ...(FindName("City", formFields) && { "City": FindLabel("City", "Member Information", pdfData)?.value }),
            ...(FindName("State", formFields) && { "State": FindLabel("State", "Member Information", pdfData)?.value }),
            ...(FindName("Member ID", formFields) && { "Member ID": FindLabel("Member ID", "Member Information", pdfData)?.value }),
            ...(FindName("Provider Name first  last", formFields) && { "Provider Name first  last": FindLabel("Provider Name (first & last)", "Prescribing Provider Information", pdfData)?.value }),
            // ...(FindName("Zip Code", formFields) && { "Zip Code": FindLabel("Zip Code", "Prescribing Provider Information", pdfData)?.value }),
            ...(FindName("NPI", formFields) && { "NPI": FindLabel("NPI#", "Prescribing Provider Information", pdfData)?.value }),
            ...(FindName("Text13", formFields) && { "Text13": FindLabel("ICD-10 Code", "Requested Medication Information", pdfData)?.value }),
            ...(FindName("IDC10 Code", formFields) && { "IDC10 Code": FindLabel("Diagnosis", "Requested Medication Information", pdfData)?.value }),

            // Tezpire pdf
            ...(FindName("T14", formFields) && { "T14": FindLabel("First Name", "PATIENT INFORMATION", pdfData)?.value }),
            ...(FindName("T15", formFields) && { "T15": FindLabel("Last Name", "PATIENT INFORMATION", pdfData)?.value }),
            ...(FindName("T45", formFields) && { "T45": FindLabel("First Name", "PRESCRIBER INFORMATION", pdfData)?.value }),
            ...(FindName("T46", formFields) && { "T46": FindLabel("Last Name", "PRESCRIBER INFORMATION", pdfData)?.value }),
            ...(FindName("T54", formFields) && { "T54": FindLabel("NPI#", "PRESCRIBER INFORMATION", pdfData)?.value }),
            ...(FindName("T104", formFields) && { "T104": FindLabel("Primary IDC10 Code", "DIAGNOSIS INFORMATION", pdfData)?.value }),
        }

        // console.log("fieldValues ", fieldValues);

        fieldValues && instance.setFormFieldValues(fieldValues);
    }

    return (
        <LoaderApp loading={patientData.isLoading} >

            <Row gutter={[16, 16]} className="row-section" justify="center" align="middle">
                <Col span={18} >
                    <TopLeft data={patientData?.data} />
                </Col>
                <Col span={6}>
                    <TopRight data={patientData?.data?.flags} />
                </Col>
            </Row>

            <Row gutter={[16, 16]} className="row-section" justify="center" align="middle">
                <Col span={18}>
                    <ActionStep paId={Number(id)} />
                </Col>
                <Col span={6}>
                    <ActionRight data={patientData?.data?.actions} />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={18}>
                    {patientData.data && (currentIndex > 0) && <PsPdfKit documentPath={`${process.env.PUBLIC_URL}/pdfs/medication/${biologic}.pdf`} setFormFieldValue={setFormFieldValue} />}
                </Col>
                <Col span={6}>
                    <GuidPanel guideData={patientData?.data} />
                </Col>
            </Row>
        </LoaderApp>
    )
}
