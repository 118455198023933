import group2052Iocn from '../../../../assets/icons/patient-table/assesment/group2052.svg';
import usenameIocn from '../../../../assets/icons/patient-table/assesment/user-name.svg';
import birthdayIocn from '../../../../assets/icons/patient-table/assesment/birthday.svg';
import FemalePatient from '../../../../assets/icons/FemalePatient.svg';
import MalePatient from '../../../../assets/icons/MalePatient.svg';
import { Avatar, Badge, Col, Row } from "antd"; 
import { FunctionComponent } from "react";
import moment from "moment";
import { dateFormatMonth } from '../../../../constants';

interface ITopLeftProps {
    data?: any;
}

const TopLeft: FunctionComponent<ITopLeftProps> = ({ data }) => {

    const defultSummary = `Here are a few coverage tips to consider: 
    • Prior Authorization requirements may vary. Certain drugs may not be covered by certain member plans. 
    • Refer to the member’s individual policy for inclusion in the PA program and medication guide for determination of coverage. 
    • Member cost share may be higher for self-administered specialty drugs not obtained at an in-network specialty pharmacy. 
    • For provider administered drugs that are buy and bill, for office or outpatient facility settings, submit requests through Availity®. 
    • For prescriptions sent to CVS Specialty, CVS Specialty will coordinate prior authorization reviews using the CVS Specialty Fax Form. 
    • CoverMyMeds is preferred for electronic Prior Authorization (ePA) requests through– a free ePA service that allows prescribers to submit and check the status of a PA request electronically. Alternatively, standard fax authorization forms are also available. 
    • If you have questions or need further assistance, please fill free to ask the AI assistant.`

    return (
        <Row gutter={[8, 8]} align="middle" justify="center" className="section">
            <Col span={3}>
                <Avatar className='antd-avatar' size={80} src={
                    data?.patient_gender == "Male"
                        ? MalePatient
                        : data?.patient_gender == "Female"
                            ? FemalePatient : undefined
                }
                    alt={data?.patient_gender}
                />
            </Col>
            <Col span={9}>
                <Row className="row-info">
                    <Col span={14}>
                        <p className='p-row-info'>
                            <img className='icon-h' src={usenameIocn} />
                            {data?.patient_name}
                        </p>
                    </Col>
                    <Col span={10}> <p className='p-row-info'>{data?.patient_age} Years ,  {data?.patient_gender}</p></Col>
                </Row>
                <Row className="row-info">
                    <Col span={12}>
                        <p className='p-row-info'>
                            <img className='icon-h' src={birthdayIocn} />
                            {moment(data?.patient_dob)?.format(dateFormatMonth)}
                        </p>
                    </Col>
                    <Col span={12}>
                        <p className='p-row-info'>
                            <img className='icon-h' src={group2052Iocn} />
                            {data?.patient_id}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}> <p className='p-row-info'>Severe Asthma</p></Col>
                </Row>
            </Col>
            <Col span={12} className="summary-section">
                <p className="font-bold">Summary :</p>
                {data?.summary?.value ?? defultSummary}
            </Col>
        </Row>
    );

}

export default TopLeft;