import FormElement from "../../../../../components/form-element";
import { mapEnumToSelectValues } from "../../../../../lib/utils";
import { paAtom } from "../../../../../atoms/atoms";
import { FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    AdministeredType,
    AuthorizationChoices,
    CoverageChoices,
    PayerEnum,
    PlanChoices,
    ServiceChoices
} from "../../../../../constants/enums";
import { useRecoilState } from "recoil";
import { Button, Card, Col, Row } from "antd";
import { useQuery } from "react-query";
import memberPlan from "../../../../../lib/api/member-plan";

interface IPaStepOneProps {
    paId: any;
    paUpdateMutation: any;
}

const PaStepOne: FunctionComponent<IPaStepOneProps> = ({ paId, paUpdateMutation }) => {
    const [paData, _p] = useRecoilState(paAtom);

    const { control, reset, handleSubmit } = useForm<any>({
        mode: "onBlur",
        defaultValues: {    
            ...paData
        },
        shouldFocusError: true,
    });

    const sharedProps = {
        control
    };

    
    useEffect(()=>{
        reset({ ...paData });  
    },[paData,reset]);

    const handleConfirm = (data: any) => {
        paUpdateMutation.mutate({ 
            submission_service: data.submission_service,
            authorization_type: data.authorization_type,
            benefit_type: data.benefit_type,
            administered_type: data.administered_type,
            id: Number(paId),
            status: 1
        });
    }

    const primary_plan = useQuery('primary plan',
        () => memberPlan.Get({ id: paData?.primary_member_plan }),
        {
            enabled: !!paData && !!paData.primary_member_plan
        }
    )?.data;
    const secondary_plan = useQuery('secondary plan',
        () => memberPlan.Get({ id: paData?.secondary_member_plan }),
        {
            enabled: !!paData && !!paData.secondary_member_plan
        }
    )?.data;

    return (
        <>
            <Row gutter={[8, 8]} align="middle" justify="center">
                <Col span={10}>
                    <Card
                        title="Primary Insurance"
                        size="small"
                        bordered={false}
                        className='pa-card'
                    >
                        <Row> <Col className="title-ac">Payer: </Col> <Col className="ml-2"> {primary_plan?.payer_name}</Col> </Row>
                        <Row> <Col className="title-ac">Plan: </Col> <Col className="ml-2"> {primary_plan?.plan_name}</Col> </Row>
                        <Row> 
                            <Col className="title-ac"> Member Id: </Col> <Col className="ml-2"> {primary_plan?.member_id}</Col>  
                            <Col className="title-ac"> , RxBin: </Col> <Col className="ml-2"> {primary_plan?.rx_bin}</Col> 
                        </Row> 
                    </Card> 
                </Col>
                <Col span={10}>
                    <Card
                        title="Secondary Insurance"
                        size="small"
                        bordered={false}
                        className='pa-card'
                    >
                        <Row> <Col className="title-ac">Payer: </Col> <Col className="ml-2"> {secondary_plan?.payer_name}</Col> </Row>
                        <Row> <Col className="title-ac">Plan: </Col> <Col className="ml-2"> {secondary_plan?.plan_name}</Col> </Row>
                        <Row> 
                            <Col className="title-ac">Member Id: </Col> <Col className="ml-2"> {secondary_plan?.member_id}</Col>  
                            <Col className="title-ac"> , RxBin: </Col> <Col className="ml-2"> {secondary_plan?.rx_bin}</Col> 
                        </Row> 
                    </Card> 
                </Col>
            </Row>
            <Row gutter={[8, 8]} align="middle" justify="center">
                <Col span={4}>
                    <FormElement
                        {...sharedProps}
                        options={mapEnumToSelectValues(ServiceChoices)}
                        placeholder="Submission Service"
                        label=''
                        type='select'
                        name='submission_service'
                    />
                </Col>
                <Col span={4}>
                    <FormElement
                        {...sharedProps}
                        options={mapEnumToSelectValues(AuthorizationChoices)}
                        placeholder="Authorization Type"
                        label=''
                        type='select'
                        name='authorization_type'
                    />
                </Col> 
                <Col span={4}>
                    <FormElement
                        {...sharedProps}
                        options={mapEnumToSelectValues(CoverageChoices)}
                        placeholder="Benefit Type"
                        label=''
                        type='select'
                        name='benefit_type'
                    />
                </Col>
                <Col span={4}> 
                    <FormElement
                        {...sharedProps}
                        options={mapEnumToSelectValues(AdministeredType)}
                        placeholder="Administered Type"
                        label=''
                        type='select'
                        name='administered_type'
                    /> 
                </Col>
                <Col span={2}>
                    <Button
                        type="primary"
                        loading={paUpdateMutation.isLoading}
                        onClick={handleSubmit(handleConfirm)}
                    >
                        Confirm
                    </Button>
                </Col>
            </Row>
        </>
    );

}

export default PaStepOne;