import { columnsInterface } from "../../../../../../models/table-columns";

export const medicationColumns: columnsInterface[] = [
  { type: "string", title: "Name", dataSelector: "name" }, 
  { 
    type: "custom", 
    title: "Dose", 
    dataSelector: "dosage_dose",
    render(text,row) {
      return row.dosage_dose + " " + row.dosage_unit
    }
  }, 
  { type: "string", title: "Rate", dataSelector: "dosage_rate" }, 
  { 
    type: "custom", 
    title: "Effictive Period", 
    dataSelector: "effictive_period_start",
    render(text,row) {
      const effectivePeriodStart:any = new Date(row.effictive_period_start);
      const effectivePeriodEnd:any = new Date(row.effictive_period_end);  

      const differenceInMilliseconds = effectivePeriodEnd.getTime() - effectivePeriodStart.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      return differenceInDays + " Day";
    }
  }, 
];
