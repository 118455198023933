import { Button, Card, Col, Row, Tooltip } from "antd";
import './assesment.scss'
import { useMutation } from "react-query";
import assessment from "../../../../../lib/api/assessment";
import { notify } from "../../../../../components/notification";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "../../../../../atoms/atoms";
import colors from "../../../../../constants/colors";
import TableHoverCriterion from "./table-hover-criterion";

const StepFour = ({ data, assessmentId }: any) => {

    const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(refetchMedicationDataAtom);

    const ConfirmMutate = () => {
        Confirm.mutate({
            id: assessmentId,
            step: 4,
        });
    }

    const Confirm = useMutation(assessment.updateStepAssessment, {
        onSuccess: (data) => {
            setRefetchMedicationData(!refetchMedicationData);
            notify(
                'success',
                'ok',
                'Success',
                'Update Step Four',
            );
        }
    });
  
    return (
        <div className="one-step">
            <Row>
                <Col flex={3}>
                    <Row> <p className="one-step-header-left">BACS Scoring System</p> </Row>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="row-card-asess">
                {
                    // Object.keys(data?.patient[0]).map((key, index) => {
                    data?.cards?.map((card: any, index: number) => (
                        <Col span={6} key={index} className={`${(index + 1) % (24 / 6) != 0 ? 'card-asess-border' : 'col-card-asess'}`}>
                            <Tooltip overlayClassName="tooltip-table" title={<p><TableHoverCriterion criteria={card.title} /></p>}>
                                <Card
                                    title={card.title}
                                    size={"small"}
                                    className={`card-asess ${index < (data?.cards?.length - (24 / 6)) ? 'card-asess-border-down' : ''}`}
                                >
                                    <p className="card-body-text">
                                        {card.list.value}
                                    </p>
                                </Card>
                            </Tooltip>
                        </Col>
                    ))
                }
            </Row>
            <Row className="row-asses-f">
                <Col span={6}>
                    <Button className="full-width" type="primary" onClick={ConfirmMutate} loading={Confirm.isLoading}>
                        Confirm Scores
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
export default StepFour;