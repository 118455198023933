import { Button, Tooltip } from 'antd';
import { Edit2, AlertOctagon, Trash2 } from 'react-feather';
import colors from '../../constants/colors';
import { columnsInterface } from '../../models/table-columns';
import { IScaffoldCustomAction } from '../../models/custom-actions';
import DeleteUserIcon from '../../assets/icons/patient-table/DeleteUser.svg';

export const actionsColumn = (
    events: {
        onDetailsRow: (id: string) => void;
        onUpdateRow: (id: string) => void;
        onDeleteRow: (id: string) => void;
        onCreateRow: () => void;
        onAnalysisDetailsRow: (id: string) => void;
        onGoToOrder: (row: any) => void;
    },
    dataName: string,
    permissions: any,
    customActions?: IScaffoldCustomAction[],
    refetchCb?: () => void,
): columnsInterface => ({
    title: 'Actions',
    dataSelector: 'actions',
    type: 'custom',
    width: 200,
    // columnWidth: 900,
    render: (text: any, row: any, index: number) => (
        <div>
            {permissions?.Delete && (
                <>
                    <Tooltip
                        title={'Delete ' + dataName}
                        key={index}
                    >
                        <Button
                            onClick={() => events.onDeleteRow(row.id)}
                            className='button-table-action'
                            icon={
                                <Trash2
                                    color={colors.dangerColor}
                                    className='icon-button-table'
                                />
                                // <img src={DeleteUserIcon} className='icon-button-table' />
                            }
                        ></Button>
                    </Tooltip>
                </>
            )}
            {permissions?.Update &&
                <>
                    <Tooltip
                        title={'Update ' + dataName}
                        key={index}
                    >
                        <Button
                            onClick={() => events.onUpdateRow(row.id)} 
                            className='button-table-action'
                            icon={
                                <Edit2
                                    size='80%' 
                                    className='icon-button-table edit-icon'
                                />
                            }
                        ></Button>
                    </Tooltip>
                </>
            }

            {permissions?.Details && (
                <>
                    <Tooltip
                        title={'Details ' + dataName}
                        key={index}
                    >

                        <Button
                            className='button-table'
                            onClick={() => events.onAnalysisDetailsRow(row.id)}
                            icon={
                                <AlertOctagon
                                    size='20px'
                                    color={colors.secondrycolor}
                                    className='icon-button-table'
                                />
                            }
                        ></Button>
                        {/* <Button
                                type='primary'
                                className='button-table-action'
                                onClick={() => events.onAnalysisDetailsRow(row.id)}
                            >
                                <AlertOctagon
                                    size='20px'
                                    color={colors.secondrycolor}
                                    className='icon-button-table'
                                />
                            </Button> */}
                    </Tooltip>
                </>
            )
            }

            {permissions?.Details && (
                <>
                    <Tooltip
                        title={'Details ' + dataName}
                        key={index}
                    >

                        <Button
                            className='button-table'
                            onClick={() => events.onAnalysisDetailsRow(row.id)}
                            icon={
                                <AlertOctagon
                                    size='20px'
                                    color={colors.secondrycolor}
                                    className='icon-button-table'
                                />
                            }
                        ></Button>
                        {/* <Button
                                type='primary'
                                className='button-table-action'
                                onClick={() => events.onAnalysisDetailsRow(row.id)}
                            >
                                <AlertOctagon
                                    size='20px'
                                    color={colors.secondrycolor}
                                    className='icon-button-table'
                                />
                            </Button> */}
                    </Tooltip>
                </>
            )
            }


            {
                customActions &&
                customActions.map((action, index) =>
                    action.msgKey ? (
                        <Tooltip
                            title={action.tooltip}
                            key={index}
                        >
                            <Button
                                type='primary'
                                onClick={() => events.onDetailsRow(row.id)}
                                icon={action.Icon}
                                key={index}
                                className='icon-button-table'
                            ></Button>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={action.tooltip}
                            key={index}
                        >
                            <Button
                                key={index}
                                onClick={() => {
                                    action.cb(row);
                                    if (action.refetch) refetchCb?.();
                                }}
                                icon={action.Icon}
                                className='button-table-action'
                            ></Button>
                        </Tooltip>
                    ),
                )
            }
        </div >
    ),
});
