import { useParams } from 'react-router-dom';
import { currentIndexStepAtom, refetchMedicationDataAtom } from '../../../atoms/atoms';
import LoaderApp from '../../../components/loader'; 
import assessment from '../../../lib/api/assessment';
import { Card, Col, Row, Tooltip } from 'antd';
import { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import colors from '../../../constants/colors';
import questionMark from '../../../assets/icons/sidebar/question-mark.svg';
import _ from 'lodash';

const SidePage: FunctionComponent<any> = ({getClarityNote}: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [currentIndexStep, _c] = useRecoilState(currentIndexStepAtom);
    const [refetchMedicationData, _r] = useRecoilState(refetchMedicationDataAtom);
    const { id: assessmentId } = useParams();

    const { data } = useQuery(
        ['assessment step', assessmentId, currentIndexStep, refetchMedicationData],
        () => assessment.getAllmedications({ id: Number(assessmentId) }),
        {
            enabled: !!assessmentId,
            onSuccess(data: any): void {
                setLoading(false);
            }
        },
    );


    return (
        <Card
            title={`Scores After Step ${currentIndexStep + 1}`}
            size={"small"}
            bordered={false}
            className='p-i-card-side'
            loading={loading}
        >
            <Card className='card-row-header'>
                <Row justify="space-around" align="middle">
                    <Col className="gutter-row text-center title-header" span={8}>
                        Biologic
                    </Col>

                    <Col className="gutter-row text-center title-header" span={8} >
                        Score
                    </Col>

                    <Col className="gutter-row text-center title-header" span={8} >
                        PA
                    </Col>
                </Row>
            </Card>
            {data?.biologics?.map((medication: any, index: number) => {
                const nameClarity = `extreme_${medication?.medication_brand.toLowerCase()}_attributes`; 
                return (
                    <Card className='card-row-side' key={index} >
                        <Row className='row-side' justify="space-around" align="middle" 
                            style={{ backgroundColor: medication.enabled == 1 ? colors.white : '', 
                                    borderColor: colors.green,
                                    borderWidth: medication.enabled == 1 ? '3px' : '0px' 
                            }}
                        >
                            <Col className="gutter-row text-center" span={8}>
                                <img src={medication.medication_url} style={{ width: "200px" }} />
                            </Col>
                            <Col className="gutter-row text-center" span={7} >
                                <p>{medication.enabled == 1 && medication?.patient_score}</p>
                            </Col>

                            <Col className="gutter-row text-center" span={7} >
                                <p>{medication.enabled == 1 && parseFloat(medication?.pa_prediction).toFixed(2) + " %"} </p>
                            </Col>
                            <Col className="gutter-row text-center" span={2}>
                                <Tooltip
                                    color={colors.blueGray} 
                                    title={<p style={{ whiteSpace: 'pre', color: colors.black }}> 
                                        {!_.isEmpty(getClarityNote?.data?.[nameClarity]) && <p> The following attributes: </p>} 
                                        {getClarityNote?.data?.[nameClarity]?.map((item:any)=> `- ${item.name} (${item.value}) \n`)} 
                                        {!_.isEmpty(getClarityNote?.data?.[nameClarity]) && <p>are far from spec and may affect <br/> PA approval </p> }
                                        </p>}
                                >
                                    <img src={questionMark} />
                                </Tooltip> 
                            </Col>
                        </Row>
                    </Card>
                )
            }) }
        </Card>
    );
}
export default SidePage;