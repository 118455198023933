import config from "./config";
import ApiService from "./baseApi/api-service";
class Pa extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
    /* @tags Pa */
    /* @name PaGet */
    /* @request GET:/api/services/app/Pa/Get */
    public Get = (params: { id?: string }): Promise<any> => {
      return this.get('/Pa/Get', {
          params,
      });
  };

  /* @tags Pa */
  /* @name PaGetAll */
  /* @request GET:/api/services/app/Pa/GetAll */
  public getAllPa = (params: {
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Pa/GetAll", {
      params,
    });
  };

  /* @tags Pa */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Pa/Create */
  public paCreate = (params: {
    assessment: number;
    biologic: string;
  }): Promise<any> => {
    return this.post("/Pa/Create", {
      ...params,
    });
  };

  /* @tags Pa */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Pa/Update */
  public paUpdate = (params: {
    id?: number;
    payer?: string;
    submitted_date?: Date;
    status?: number;
    outcome?: number;
    denial_reason?: number;
  }): Promise<any> => {
    return this.put(`/Pa/Update`, {
      ...params,
    });
  };

  /* @tags Pa */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Pa/Delete */
  public paDelete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Pa/Delete`,{params});
  };

  /* @tags Pa */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Pa/Delete */
  public getPaPaInformation = (params: {
    id?: string;
    payer?: string;
    biologic?: string;
  }): Promise<any> => {
    return this.get(`/Pa/GetPaInfo`, {
      params,
    });
  };
}
const pa = new Pa();
export default pa;
