import config from "./config";
import ApiService from "./baseApi/api-service";
class Plan extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
    /* @tags Plan */
    /* @name PlanGet */
    /* @request GET:/api/services/app/Plan/Get */
    public Get = (params: { id?: number }): Promise<any> => {
      return this.get('/Plan/Get', {
          params,
      });
  };

  /* @tags Plan */
  /* @name PlanGetAll */
  /* @request GET:/api/services/app/Plan/GetAll */
  public getAll = (params: {
    payer_id?: number;
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Plan/GetAll", {
      params,
    });
  }; 

  /* @tags Plan */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Plan/Create */
  public Create = (params: {
    payer: number; 
    plan_name: string;
    plan_number: number;
    plan_type: string; 
  }): Promise<any> => { 
    return this.post("/Plan/Create", {
      ...params,
    });
  };

  /* @tags Plan */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Plan/Update */
  public Update = (params: {
    id?: number;
    payer: number; 
    plan_name: string;
    plan_number: number;
    plan_type: string; 
  }): Promise<any> => {
    return this.put(`/Plan/Update`, {
      ...params,
    });
  };

  /* @tags Plan */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Plan/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Plan/Delete`,{params});
  };
}
const plan = new Plan();
export default plan;
