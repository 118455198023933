import config from "./config";
import ApiService from "./baseApi/api-service";
class Observation extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }

  /* @tags Observation */
    /* @name ObservationGet */
    /* @request GET:/api/services/app/Observation/Get */
    public Get = (params: { id?: number }): Promise<any> => {
      return this.get('/Observation/Get', {
          params,
      });
  };

  /* @tags Observation */
  /* @name ObservationGetAll */
  /* @request GET:/api/services/app/Observation/GetAll */
  public getAll = (params: {
    patient_id?: string;
    assessment_id?: string;
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Observation/GetAll", {
      params,
    });
  };

  public getAllLite = (params: {
    patient_id?: number;
    category?: number;
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Observation/GetAllLite", {
      params,
    });
  };

  /* @tags Observation */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Observation/Create */
  public Create = (params: {
    patient: number;
    code: string;
    display: string;
    start_date: Date;
  }): Promise<any> => { 
    return this.post("/Observation/Create", {
      ...params,
    });
  };

  /* @tags Observation */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Observation/Update */
  public Update = (params: {
    id?: number;
    payer?: string;
    submitted_date?: Date;
    status?: number;
    outcome?: number;
    denial_reason?: number;
  }): Promise<any> => {
    return this.put(`/Observation/Update`, {
      ...params,
    });
  };

  /* @tags Observation */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Observation/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Observation/Delete`,{params});
  };
}
const observation = new Observation();
export default observation;
