import { useParams } from "react-router-dom";
import GeneralTable from "../../../../../components/general-table";
import { observationColumns } from "./data";
import ObservationForm from "./forms";
import observation from "../../../../../lib/api/observation";

const Observation = () => {
    const { patientId: patient_id , id: assessment_id} = useParams();  
    
    return (
        <GeneralTable
            dataName="Observation" 
            getAllFunc={observation.getAll}
            getAllParams={{ assessment_id, patient_id }} 
            getFunc={observation.Get}
            delete
            deleteFunc={observation.Delete}
            create
            createFunc={observation.Create}
            formContent={ObservationForm}   
            update
            updateFunc={observation.Update}
            tableColumns={observationColumns}   
            FormSubmitMapper={(data: any) => {
                return {
                    ...data,
                    patient: patient_id,
                    assessment_id: assessment_id
                };
            }}  
        />
    );
}
export default Observation;