import config from "./config";
import ApiService from "./baseApi/api-service";
class Payer extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Payer */
    /* @name PayerGet */
    /* @request GET:/api/services/app/Payer/Get */
    public Get = (params: { id?: number }): Promise<any> => {
      return this.get('/Payer/Get', {
          params,
      });
  };

  /* @tags Payer */
  /* @name PayerGetAll */
  /* @request GET:/api/services/app/Payer/GetAll */
  public getAll = (params: { 
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Payer/GetAll", {
      params,
    });
  };

  /* @tags Payer */
  /* @name get_all_lite */
  /* @request GET:/api/services/app/payer/get_all_lite */
  public getAllLitePayer = (params: {
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Payer/get_all_lite/", {
      params,
    });
  };

  /* @tags Payer */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Payer/Create */
  public Create = (params: {
    name: string; 
  }): Promise<any> => { 
    return this.post("/Payer/Create", {
      ...params,
    });
  };

  /* @tags Payer */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Payer/Update */
  public Update = (params: {
    id?: number;
    payer?: string;
    submitted_date?: Date;
    status?: number;
    outcome?: number;
    denial_reason?: number;
  }): Promise<any> => {
    return this.put(`/Payer/Update`, {
      ...params,
    });
  };

  /* @tags Payer */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Payer/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Payer/Delete`,{params});
  }; 

}
const payer = new Payer();
export default payer;
