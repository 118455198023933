import config from "./config";
import ApiService from "./baseApi/api-service";
import { IFormStep } from "../../models/form-step";
class Assessment extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }

  /* @tags Condition */
  /* @name ConditionGet */
  /* @request GET:/api/services/app/Condition/Get */
  public Get = (params: { id?: number }): Promise<any> => {
    return this.get('/Assessment/Get', {
        params,
    });
  };

  /* @tags Pa */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Pa/Create */
  public createAssessment = (params: { patient?: number }): Promise<any> => {
    return this.post("/Assessment/Create", {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetAll */
  public getAllAssessment = (params: {
    page?: number;
    search?: string;
    page_size?: string;
    patient_id?: string;
  }): Promise<any> => {
    return this.get("/Assessment/GetAll", {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetAll */
  public getAllmedications = (params: { id: number }): Promise<any> => {
    return this.get(`/Assessment/GetBiologics`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetAll */
  public getAllScores = (): Promise<any> => {
    return this.get("/Assessment/GetScores");
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetAll */
  public getStepAssessmentById = (params: {
    id?: number;
    step: number;
  }): Promise<any> => {
    return this.get(`/Assessment/GetStep`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentUpdate */
  /* @request GET:/api/services/app/assessment/Update */
  public updateStepAssessment = (params: IFormStep): Promise<any> => {
    return this.put(`/Assessment/UpdateStep`, {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/assessment/Create */
  public assessmentCreate = (params: any): Promise<any> => {
    return this.post("/Assessment/Create", {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/assessment/Delete */
  public assessmentDelete = (params: {
    assessmentId: number;
  }): Promise<any> => {
    return this.delete(`/Assessment/Delete`, {
      params,
    });
  }; 

  public getClarityNote = (params: { id: number }): Promise<any> => {
    return this.get('/Assessment/GetClarityNote', {
        params,
    });
  };
}
const assessment = new Assessment();
export default assessment;
