import { Table, TableProps } from "antd";
import { useQuery } from "react-query";
import assessment from "../../../../../lib/api/assessment";

const TableHoverCriterion = ({criteria}:any) => {

    const scoresQuery = useQuery(
        'scores',
        () => assessment.getAllScores(),
    );

    const columns: TableProps['columns']  = [
        {
            key: 'point',
            title: 'Point',
            dataIndex: 'point', 
        },
        {
            key: 'rule',
            title: 'Rule',
            dataIndex: 'rule', 
        }, 
    ];

    const score = scoresQuery?.data ? Object.keys(scoresQuery?.data).find(score => score == criteria) : undefined;
    const dataSourceTable = score && scoresQuery?.data[score].list  

    return  <Table
                className="table-hover"
                columns={columns} 
                dataSource={dataSourceTable}
                pagination={false} 
                title={()=> <p className="font-bold"> {score} </p>}
            /> 
}

export default TableHoverCriterion;