import '../../styles/input/index.scss';
import '../../styles/select/index.scss';
import { Select as AntdSelect } from 'antd';
import { FunctionComponent, useState } from 'react';
import { useAnimation } from 'framer-motion';
import {
    defaultTranstion,
    maxBackgroundWidth,
    minBackgroundWidth,
} from '../../constants/framer';
import { Controller } from 'react-hook-form';
import FromElemnetWrapper from '../form-element-wrapper';
import { ValueLabelDto } from '../../models/value_label';
import { useQuery } from 'react-query';
import condition from '../../lib/api/condition';
import { DefaultOptionType } from 'antd/es/select';

interface InputProps {
    name: string;
    control: any;
    rules?: any;
    allowClear?: boolean;
    options?: ValueLabelDto[] | undefined;
    placeholder?: string;
    label: string;
    labelSide?: string;
    multiple?: boolean;
    disabled?: boolean;
    labelInValue?: boolean;
    defaultValue?: number | any;
    onChange?: (value: any, option: DefaultOptionType) => void;
    getAllFunc?: (data: any) => Promise<any>;
    optionDataMapper?: (row: Object[]) => Object[];
}
const SelectWithPage: FunctionComponent<InputProps> = ({
    name,
    control,
    rules,
    placeholder,
    label,
    labelSide,
    options,
    allowClear,
    multiple,
    disabled,
    defaultValue,
    labelInValue,
    onChange: onPropsChange,
    getAllFunc: getAllFunc,
    optionDataMapper: optionDataMapper
}) => {
    const focusController = useAnimation();
    const errorController = useAnimation();
    const [page , setPage] = useState(0);
    const [search , setSearch] = useState<string>(); 
    const page_size = 15;

    const getAllOption = useQuery([label,page,search], 
        () =>   getAllFunc ?  getAllFunc({  
            page: page,
            page_size: page_size,
            search: search ?? defaultValue
        })  : new Promise(() => { })
    ); 

    return (
        <>
            <FromElemnetWrapper
                required={rules.required}
                focusController={focusController}
                errorController={errorController}
                label={label}
                labelSide={labelSide}
            >
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    defaultValue={defaultValue}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                    }) => {
                        if (invalid) {
                            errorController.start(maxBackgroundWidth);
                        } else {
                            errorController.start(minBackgroundWidth, {
                                ease: defaultTranstion.ease.reverse(),
                            });
                        }
                        return (
                            <div className='relative'>
                                <AntdSelect
                                    showSearch
                                    onFocus={() => {
                                        focusController.start(
                                            maxBackgroundWidth,
                                        );
                                    }}
                                    labelInValue={labelInValue ?? false}
                                    aria-label={label}
                                    loading={getAllOption.isLoading}
                                    // options={options}
                                    options={
                                        optionDataMapper
                                        ? optionDataMapper(getAllOption?.data)
                                        : getAllOption?.data
                                    }
                                    allowClear={allowClear}
                                    onChange={(v,option) => { 
                                        onPropsChange && onPropsChange(v,option);
                                        onChange(v);
                                    }}
                                    // value={value ? value : defaultValue}
                                    value={value}
                                    defaultValue={defaultValue}
                                    ref={ref}
                                    id={name}
                                    mode={
                                        multiple ? 'multiple' : undefined
                                    }
                                    className={
                                        invalid
                                            ? 'ant-custom-input-error'
                                            : 'ant-custom-input'
                                    }
                                    onBlur={() => {
                                        onBlur();
                                        focusController.start({
                                            width: 0,
                                            height: 0,
                                        });
                                    }}
                                    filterOption={(input, option: any) =>{
                                        setSearch(input); 
                                        return option?.label
                                            .toLowerCase()
                                            .indexOf(
                                                input?.toLowerCase(),
                                            ) >= 0
                                        }
                                    }
                                    onPopupScroll={(e: any)=>{
                                        const target = e.target;
                                        if (target.scrollTop + target.offsetHeight === target.scrollHeight ) {
                                          setPage(page + page_size);
                                        } 
                                        else if ( target.scrollTop === 0 && page > 0) {
                                            setPage(page - page_size);
                                        } 
                                    }}
                                    bordered={false}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                />
                                {invalid && (
                                    <p className='form-element-error'>
                                        {error?.message}
                                    </p>
                                )}
                            </div>
                        );
                    }}
                />
            </FromElemnetWrapper>
        </>
    );
};

export default SelectWithPage;
