import '../../styles/checkbox/index.scss';
import { Checkbox as AntCheckbox } from 'antd';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import FromElemnetWrapper from '../form-element-wrapper';
import { useAnimation } from 'framer-motion';
interface InputProps {
    name: string;
    control: any;
    rules?: any;
    label: string;
    labelSide?: string; 
    isChecked?: boolean;
    disabled?: boolean;
    onChange?: (value: any) => void;
}
const Checkbox: FunctionComponent<InputProps> = ({
    name,
    rules,
    control,
    label,
    labelSide,
    isChecked,
    disabled,
    onChange: onPropsChange,
}) => {

    const focusController = useAnimation();
    const errorController = useAnimation();

    return (
        <>
            <FromElemnetWrapper
                required={rules.required?.value}
                focusController={focusController}
                errorController={errorController}
                label=" "
                labelSide={labelSide}
            >
                <Controller
                    control={control}
                    name={name}
                    render={({
                        field: { onChange, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                    }) => {
                        return (
                            <div className='relative'>
                                <AntCheckbox
                                    onChange={(v) => {
                                        onPropsChange &&
                                            onPropsChange(v.target.value ? v.target.value : v.target.checked);
                                        onChange(v);
                                    }}
                                    value={value}
                                    ref={ref}
                                    id={name}
                                    defaultChecked={isChecked ?? value}
                                    disabled={disabled}
                                    className={
                                        invalid
                                            ? 'ant-custom-input-error'
                                            : 'ant-custom-input'
                                    }
                                >
                                    {label}
                                </AntCheckbox>
                                {invalid && (
                                    <p className='form-element-error'>
                                        {error?.message}
                                    </p>
                                )}
                            </div>
                        );
                    }}
                />
            </FromElemnetWrapper>
        </>
    );
};

export default Checkbox;
