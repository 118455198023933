// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #179c8b;
  --secondry-color: #3B87FB;
  --hover-color: #4ed6c9;
}

.ant-checkbox,
.ant-checkbox-input,
.ant-checkbox-inner {
  border-radius: 0.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables/colors.scss","webpack://./src/styles/checkbox/index.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,yBAAA;EACA,sBAAA;ACCJ;;AAHA;;;EAGI,gCAAA;AAMJ","sourcesContent":[":root {\n    --primary-color: #179c8b;\n    --secondry-color: #3B87FB;\n    --hover-color: #4ed6c9;\n}\n\n$primary: var(--primary-color);\n$secondry: var(--secondry-color);\n$hover: var(--hover-color);\n$gray: #acacac;\n$white: #ffffff;\n$primary-extra-light: #c1ecea;\n$backgroundPrim: var(--Grey-Lite, #FAFAFA);\n$form-element-background: #F2F3F7;\n$submenuebackground: #cbe4e6;\n$soft-form-color: #e7f7f6;\n$selectmenu: #d1edefed;\n$black: #232528;\n$grey: #3f3f3f;\n$light: #f6f6f6;\n$light-gray: #f2f3f7;\n$light-semiGray: #e5e5e5;\n$semiDark: #aaaaaa;\n$blue-gray: #969FB7;\n$danger: #ea5455;\n$danger-extra-light: lighten($color: $danger, $amount: 30);\n$semiGreen: #28C76F;\n$orange: #f79501;\n$semiOrange: #F8AF4133;\n$form-element-border-radius: 1.3rem;\n$upload-element-border-radius: 2rem;\n$checkbox-border-radius: 0.5rem;\n$navbar-border-radius: 0.1rem;\n$navbar-border-radius: 0.1rem;\n$button-radius: 0.5rem;","@import '../variables/colors.scss';\n.ant-checkbox,\n.ant-checkbox-input,\n.ant-checkbox-inner {\n    border-radius: $checkbox-border-radius !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
