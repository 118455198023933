import { Button, Card, Col, Row } from "antd";
import './assesment.scss'
import { dataChart, mapEnumToSelectValues, optionsChart } from "../../../../../lib/utils";
import { PhenoType } from "../../../../../constants/enums";
import { useMutation, useQueryClient } from "react-query";
import assessment from "../../../../../lib/api/assessment";
import { notify } from "../../../../../components/notification";
import { IFormStep } from "../../../../../models/form-step";
import { useForm } from "react-hook-form";
import FormElement from "../../../../../components/form-element";
import Chart from "react-google-charts";
import LableColor from "../../../../../components/lable-color";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "../../../../../atoms/atoms";
import { Plus } from "react-feather";
import colors from "../../../../../constants/colors";
import { useState } from "react";
import ModalObservation from "./modal-observation";
import missingIocn from '../../../../../assets/icons/patient-table/assesment/missing.svg';
import ModalConfirm from "./modal-confirm";
import _ from "lodash";
import moment from "moment";
import { dateFormatMonth } from "../../../../../constants";

const StepTwo = ({ data, assessmentId }: any) => {

    const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(refetchMedicationDataAtom);
    const [formVisable, setFormVisable] = useState<boolean>(false);
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);  
    const [cardInfo, setCardInfo] = useState<number>();
    const queryClient = useQueryClient();

    const { control, reset, handleSubmit, setValue } = useForm<IFormStep>({
        mode: "onBlur",
        defaultValues: {
            phenotype: data.Phenotype ? data.Phenotype?.value : data.suggested_phenotype?.value
        },
        shouldFocusError: true,
    });

    const sharedProps = {
        control
    };

    const ConfirmMutate = (data: IFormStep) => {
        Confirm.mutate({
            ...data,
            id: assessmentId,
            step: 2,
        });
    }

    const OnSubmitResetValue = () => {
        setValue?.('phenotype', data?.suggested_phenotype?.value);   
    }

    const Confirm = useMutation(assessment.updateStepAssessment, {
        onSuccess: (data) => {
            setConfirmVisible(false); 
            setRefetchMedicationData(!refetchMedicationData);
            notify(
                'success',
                'ok',
                'Success',
                'Update Step Two',
            );
            queryClient.invalidateQueries(['getClarityNote']);   
        }
    });


    return (
        <div className="one-step">
            <ModalObservation 
                setFormVisable={setFormVisable}
                formVisable={formVisable}
                cardInfo={cardInfo}
            /> {/* modal create observation after click on card */}
            <ModalConfirm 
                formVisable={confirmVisible} 
                setFormVisable={setConfirmVisible}
                onConfirmHandle={handleSubmit(ConfirmMutate)}
                data={data?.cards}
            />
            <Row>
                <Col flex={3}>
                    <Row>
                        <p className="one-step-header-left">Biomarkers and Tests</p>
                    </Row>
                </Col>
                <Col flex={0.1}>
                    <div className="one-step-header-right">
                        <Row justify='space-between'>
                            {LableColor('Normal', '#00B050')}
                            {LableColor('High', '#d0f444')}
                            {LableColor('Very High', '#EA2323')}
                            {LableColor('Empty', '#A6A6A6')}
                            {LableColor('Positive', '#6c005a')}
                            {LableColor('Negative', '#5779ff')}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="row-card-asess">
                {
                    data?.cards?.map((card: any, index: number) => {

                        return (
                            <Col span={8} key={index} className={`${(index + 1) % (24 / 8) != 0 ? 'card-asess-border' : 'col-card-asess'}`}>
                                <Card
                                    title={
                                        <>
                                            <Row justify="space-between">
                                                <Col> {card.title} </Col> 
                                                <Col > <Plus className='pr-1' color={colors.primaryColor} size="25" /> </Col>
                                            </Row>
                                            {card.display_all === "yes" && 
                                                <Row justify="space-between">
                                                    <Col style={{ fontWeight: "normal", color: colors.primaryColor }}> 
                                                        {!_.isEmpty(card.list) && card.list[0].value + " " + card.unit + ", " + moment(card.list[0].date).format(dateFormatMonth)}  
                                                    </Col>  
                                                </Row>
                                            }
                                        </>
                                    }
                                    size={"small"}
                                    className={`card-asess ${index < (data?.cards.length - (24 / 8)) ? 'card-asess-border-down' : ''}`}
                                    onClick={() => {
                                        setFormVisable(true)
                                        setCardInfo(card);
                                    }}
                                >
                                    {
                                        card.display_all === "no" ?
                                            (
                                                <p className="card-body-text">
                                                    {card.list[0]?.value !== "N/A" ? (card.list[0] ? JSON.stringify(card.list[0]?.value).replace(/,/g, '\n').replace(/[{}"]/g, '') : "" + " " + card.unit)
                                                        : <img className='icon-h' src={missingIocn} />
                                                    }
                                                </p>
                                            )
                                            : (
                                                <Chart
                                                    chartType={card.graph == "line" ? "LineChart" : "ColumnChart"}
                                                    className="card-body-chart"
                                                    height="85px"
                                                    width="100%"
                                                    options={optionsChart(card)}
                                                    data={dataChart(card)}
                                                />
                                            )
                                    }
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className="row-asses-f">
                <Col span={3}> <p className="full-width" > Phenotype </p> </Col>
                <Col span={7}>
                    <FormElement
                        {...sharedProps}
                        // value={data?.Phenotype !== 0 ? data?.Phenotype : data?.suggested_phenotype}
                        options={mapEnumToSelectValues(PhenoType)}
                        // className="full-width"
                        placeholder="Allergic"
                        label=''
                        type='select'
                        name='phenotype'
                        required
                    />
                </Col>
                <Col span={4}>
                    <Button
                        className="full-width"
                        onClick={()=>{
                            OnSubmitResetValue();
                        }}
                    >
                        Reset to default
                    </Button>
                </Col>
                <Col span={6}>
                    <Button className="full-width" type="primary" 
                        onClick={()=>{
                            setConfirmVisible(true);
                        }}
                    >
                        Confirm Phenotype
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
export default StepTwo;