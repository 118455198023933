import { Button, Col, Row } from "antd";
import './assesment.scss'
import leftIcon from '../../../../../assets/icons/patient-table/assesment/left.svg'
import rightIcon from '../../../../../assets/icons/patient-table/assesment/right.svg'
import StepOne from "./step-one";
import { useEffect, useMemo, useState } from "react";
import StepTwo from "./step-two";
import StepThree from "./step-three";
import StepFour from "./step-four";
import StepFive from "./step-five";
import { useQuery } from "react-query";
import assessment from "../../../../../lib/api/assessment";
import _ from "lodash";
import LoaderApp from "../../../../../components/loader";
import { useRecoilState } from "recoil";
import { currentIndexStepAtom } from "../../../../../atoms/atoms";
import StepsDiv from "../../../../../components/steps";

const Assessment = ({ assessmentId }: any) => {

    const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexStepAtom);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();

    const currentInternal = (index: number) => {
        switch (index) {
            case 0:
                return <StepOne data={data!} assessmentId={assessmentId} />;
            case 1:
                return <StepTwo data={data} assessmentId={assessmentId} />;
            case 2:
                return <StepThree data={data} assessmentId={assessmentId} />;
            case 3:
                return <StepFour data={data} assessmentId={assessmentId} />;
            case 4:
                return <StepFive data={data} assessmentId={assessmentId} />;
        }
    }

    useQuery(
        ['assessmentStep', assessmentId, currentIndex],
        () =>
            assessment.getStepAssessmentById({ id: assessmentId, step: currentIndex + 1 }),
        {
            enabled: !!assessmentId,
            onSuccess(data: any): void {
                setData(data);
                setLoading(false);
            },
        },
    );

    useEffect(()=>{
        setLoading(true)
    },[assessmentId])
    
    const expensiveResult = useMemo(() => {
        return currentInternal(currentIndex);
    }, [assessmentId, data]);

    return (
        <div className="assesment">
            <LoaderApp loading={loading}>
                <StepsDiv
                    current={currentIndex}
                    items={[
                        {
                            title: 'Severity',
                        },
                        {
                            title: 'Phenotype',
                        },
                        {
                            title: 'Treatment Type',
                        },
                        {
                            title: 'Accessibility Scores',
                        },
                        {
                            title: 'PA Charts',
                        },
                    ]}
                    onStepClick = {
                        (current:number) => { 
                            setCurrentIndex(current);
                            setLoading(true);
                        }
                    }
                />
                <Row className="assesment-contant">
                    <Col span={1}>
                        <Button
                            style={{ display: `${currentIndex <= 0 ? 'none' : ''}` }}
                            className="btn-step"
                            icon={
                                <img src={leftIcon}
                                    onClick={() => {
                                        setCurrentIndex(currentIndex - 1)
                                        setLoading(true);
                                    }}
                                />
                            }
                        />
                    </Col>
                    <Col span={22}>
                        {data && expensiveResult}
                    </Col>
                    <Col span={1}>
                        <Button
                            style={{ display: `${currentIndex > 3 ? 'none' : ''}` }}
                            className="btn-step"
                            icon={
                                <img src={rightIcon}
                                    onClick={() => {
                                        setCurrentIndex(currentIndex + 1)
                                        setLoading(true);
                                    }
                                    }
                                />
                            }
                        />
                    </Col>
                </Row>

            </LoaderApp>
        </div>
    );
}
export default Assessment;