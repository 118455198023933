import { Button, Col, Row } from "antd";
import { FunctionComponent } from "react";

interface IPaStepThreeProps {
    paId: number;
    paUpdateMutation: any;
}

const PaStepThree: FunctionComponent<IPaStepThreeProps> = ({ paUpdateMutation, paId }) => {

    const handleConfirm = (data: any) => {
        paUpdateMutation.mutate({
            id: Number(paId),
            submitted_date: new Date(),
            status: 3
        });
    }

    return (
        <Row gutter={[8, 8]} align="middle" justify="end">
            <Col span={4}>
                <Button
                    type="primary"
                    onClick={handleConfirm}
                >
                    Submision
                </Button>
            </Col>
        </Row>
    );

}

export default PaStepThree;