import GeneralTable from '../../components/general-table';
import patient from '../../lib/api/patient';
import { patientColumns } from './data';
import RefreshIcon from '../../assets/icons/patient-table/Refresh.svg';
import DetailsIcon from '../../assets/icons/patient-table/Details.svg';
import AssessmentIcon from '../../assets/icons/sidebar/assessment.svg';
import { getAge } from "../../lib/utils";
import { Gender } from "../../constants/enums";
import AssesmentModal from "./assesment-modal";
import { useState } from "react";

export default function PatientPage() {
    const [isOpenAssesment, setIsOpenAssesment] = useState(false);
    const [patientId, setPatientId] = useState();

    return (
        <>
            <GeneralTable
                headerTable="Biologics Accessibility Scoring System"
                dataName="Patient"
                getAllFunc={patient.getAllPatients}
                delete
                deleteFunc={patient.patientDelete}
                tableColumns={patientColumns}
                customCreateAction={() => { }}
                customOnRowClick={() => { }}
                tableDataMapper={(rows) => {
                    return rows?.map((row: any, index: number) => {
                        return {
                            ...row,
                            name: row?.fname + " " + row?.lname,
                            age: getAge(row?.birth_date) + " Y",
                            gender: Gender[row?.gender]
                        };
                    });
                }}
                customActions={[
                    {
                        Icon: <img src={RefreshIcon} className='icon-button-table' />,
                        cb: (row) => {
                            // FHIR.oauth2.ready().then(() => {
                            //     // function to refresh patient
                            // }).catch(() => {
                            //     fhirAuthorize()
                            // })
                        },
                        tooltip: 'Refresh',
                    },
                    {
                        Icon: <img src={AssessmentIcon} className='icon-button-table' />,
                        // items: { items },
                        cb: (row) => {
                            setIsOpenAssesment(true)
                            setPatientId(row.id);
                            // navigate(`/auth/assessment/${row?.id}`);
                        },
                        tooltip: 'Assessment'
                    },
                    {
                        Icon: <img src={DetailsIcon} className='icon-button-table' />,
                        cb: (row) => {
                            // router.push(`/patients/${id}`)
                        },
                        tooltip: 'Patient Details',
                    },
                ]}
            />
            {isOpenAssesment && <AssesmentModal
                setIsOpenAssesment={setIsOpenAssesment}
                isOpenAssesment={isOpenAssesment}
                patientId={patientId}
            />}

        </>
    )
}
