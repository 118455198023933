import React from 'react'; 
import { Card, Col, Row } from 'antd';  
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import payer from '../../../../lib/api/payer';
import { notify } from '../../../../components/notification';
import GeneralFormContainer from '../../../../components/general-form-containner';
import PayerForm from '.';
import PlanPage from '../../plan';
import DrugPolicyPage from '../../drug-policy';

interface PayerFormProps  { }

const PayerInfo: React.FC<PayerFormProps> = ( ) => { 
    const { id } = useParams();
     
    const updatePayerMutation = useMutation(payer.Update, {
        onSuccess: () => notify('success', 'ok', 'updateMsg')
    });

    return ( 
        <Card
            title={
                <h1 className='text-primary'>
                     Payer Info 
                </h1>
            }
        >
            <GeneralFormContainer
                dataName='Payer Info'
                type='Update'
                actionLoading={ updatePayerMutation.isLoading } 
                onSubmit={(data: any) => {
                    updatePayerMutation.mutate(data);
                }}
                detailsMapper={(data: any) => {
                    return {
                        ...data, 
                    };
                }}
                getFunc={() => payer.Get({id: Number(id)})}
                content={PayerForm} 
            /> 
            <PlanPage />
            <DrugPolicyPage />
        </Card>
    );
};

export default PayerInfo;
