/* eslint-disable react-hooks/exhaustive-deps */
import { Layout, Menu } from 'antd';
import '../../styles/sidebar/index.scss';
import { FunctionComponent, useState } from 'react';
import _ from 'lodash';

export interface Children {
    path: string;
    label: string;
    link: string;
    icon: JSX.Element;
}
export interface MenuItems {
    label: string;
    link: string;
    icon: any;
    children?: Children[];
    permissions?: string[];
}
export interface SideBarProps {
    header?: any;
    footer?: JSX.Element | string;
    MenuItems: MenuItems[];
    collapsed?: boolean;
    onChange: (link: string) => void;
}

const SideBar: FunctionComponent<SideBarProps> = (props) => {
    const [activeIndex, setActiveIndex] = useState<number>();
    const { Sider } = Layout;

    return (
        <div className='app-sider'>
            <Sider
                collapsed={props.collapsed}
                collapsible
                trigger={null}
                className='sider-layout'
            >
                <Menu mode='inline' selectedKeys={[String(activeIndex)]}>
                    {props.MenuItems.map((item, index) => {
                        return (
                            <Menu.Item
                                key={index}
                                onClick={() => {
                                    setActiveIndex(index);
                                    props.onChange(item.link);
                                }}
                                title={item.label}
                                className='sidebar-m-i'
                            >
                                <img src={item.icon} className='icon-img' />
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </Sider>
        </div>
    );
};
export default SideBar;
