import { Switch } from "antd";
import { Check, X } from "react-feather";
import { columnsInterface } from "../../models/table-columns";

export const switchColumn = (
    onSwitchChange: (id: number) => void,
    loading: boolean,
    switchActivationColumnKey: string = 'status',
    switchActivationColumnDataSelector: string = 'isActive',
): columnsInterface => ({
    title: switchActivationColumnKey,
    type: 'custom',
    align: 'center',
    dataSelector: switchActivationColumnDataSelector,

    render: (value: any, row: any) => {
        return (
            <>
                <Switch
                    defaultChecked={value}
                    checked={value}
                    disabled={loading}
                    onChange={(e: any) => onSwitchChange(row.id)}
                    loading={loading}
                    unCheckedChildren={<X size={18} />}
                    checkedChildren={<Check size={18} />}
                />
            </>
        );
    },
});