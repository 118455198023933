import { FunctionComponent } from "react";
import LoaderApp from "../../components/loader";
import BasicModal from "../../components/modal"; 
import _ from "lodash";
import moment from "moment";
import { useRecoilState } from "recoil";
import { currentIndexStepAtom } from "../../atoms/atoms";
import { useMutation } from "react-query";
import { notify } from "../../components/notification";
import assessment from "../../lib/api/assessment";
import { StepAssesEnum } from "../../constants/enums";
import { useNavigate, useParams } from "react-router-dom";
import colors from "../../constants/colors";
import GeneralTable from "../../components/general-table";
import { columnsInterface } from "../../models/table-columns";
import { dateFormatMonth } from "../../constants";

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}

interface IAssesmentModle { 
    isOpenAssesment: boolean;
    patientId?: number;
    setIsOpenAssesment: (item: boolean) => void;
}

const AssesmentModal: FunctionComponent<IAssesmentModle> = ({ 
    isOpenAssesment,
    patientId,
    setIsOpenAssesment
}) => {
    const [_c, setCurrentIndexStep] = useRecoilState(currentIndexStepAtom); 
    const navigate = useNavigate(); 
    const { id: assessmentId } = useParams();

    const createAssessment = useMutation(assessment.createAssessment,
        {
            onSuccess: (err: any) => {
                notify(
                    'success',
                    'ok',
                    'Success',
                    'Create New Assessment',
                );
            }
        },
    );

    const columns: columnsInterface[] = [
        {
            title: 'Id',
            dataSelector: 'id',
            type: 'string',
        },
        {
            title: 'Patient Name',
            dataSelector: 'name',
            type: 'string',
        },
        {
            title: 'Practitioner',
            dataSelector: 'practitioner',
            type: 'string',
        },
        {
            title: 'Assessment Date',
            dataSelector: 'date',
            type: 'date',
        },
        {
            title: 'Current Step',
            dataSelector: 'current_step',
            type: 'custom',
            render(value,row) { 
                return (
                    row?.current_step + "/5 " + StepAssesEnum[row?.current_step] 
                )
            },
        }
    ];

    return (
        <BasicModal
            header={<h3 className='text-primary'> Assessment List </h3>}
            headerType='normal'
            content={ 
                createAssessment.isLoading ? <LoaderApp loading={createAssessment.isLoading} /> :
                    <> 
                        <GeneralTable 
                            dataName="Assessment"
                            getAllFunc={() => assessment.getAllAssessment({ patient_id: String(patientId) })}
                            delete
                            deleteFunc={assessment.assessmentDelete}
                            tableColumns={columns}
                            create
                            customCreateAction={() => { createAssessment.mutate({ patient: patientId }) }}
                            customOnRowClick={(row) => { }} 
                            onRow={(row: any) => ({
                                onClick: () => {
                                    navigate(`/auth/assessment/${row.id}/${row.patient}`); 
                                    setCurrentIndexStep((row.current_step - 1));
                                    setIsOpenAssesment(false);  
                                },
                                style: row.id == assessmentId ? { backgroundColor: colors.hovercolor} : undefined,
                            })}
                            tableDataMapper={(rows) => {
                                return rows?.map((row: any, index: number) => {
                                    return {
                                        ...row,
                                        name: row?.fname + " " + row?.lname,
                                        date: moment(row.date).format(dateFormatMonth),
                                        style: {backgroundColor: colors.hovercolor}
                                    };
                                });
                            }} 
                        />
                    </>
            }
            isOpen={isOpenAssesment}
            footer={false}
            width='55vw'
            handleCancel={() => {
                setIsOpenAssesment(false);
            }}
        />
    )

}

export default AssesmentModal;