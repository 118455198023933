import { Button, Card, Col, Row, Select, Steps } from "antd";
import './assesment.scss'
import { dataChart, mapEnumToSelectValues, optionsChart } from "../../../../../lib/utils";
import { TreatmentTypes } from "../../../../../constants/enums";
import { useForm } from "react-hook-form";
import { IFormStep } from "../../../../../models/form-step";
import { useMutation, useQueryClient } from "react-query";
import assessment from "../../../../../lib/api/assessment";
import { notify } from "../../../../../components/notification";
import FormElement from "../../../../../components/form-element";
import Chart from "react-google-charts";
import _ from "lodash";
import LableColor from "../../../../../components/lable-color";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "../../../../../atoms/atoms";
import { Plus } from "react-feather";
import colors from "../../../../../constants/colors";
import { useEffect, useState } from "react";
import ModalCondition from "./modal-condition";

const StepThree = ({ data, assessmentId }: any) => {

    const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(refetchMedicationDataAtom);
    const [formVisable, setFormVisable] = useState<boolean>(false);
    const [cardInfo, setCardInfo] = useState<any>(); 
    const queryClient = useQueryClient(); 

    const { control, reset, handleSubmit, setValue } = useForm<IFormStep>({
        mode: "onBlur",
        defaultValues: {
            treatment_type: !_.isEmpty(data?.treatment_type) ? data.treatment_type?.map((item: string) => Number(item)) : data.suggested_treatment_type?.map((item: string) => Number(item))
        },
        shouldFocusError: true,
    });

    const sharedProps = {
        control
    };

    const OnSubmitResetValue = () => {
        setValue?.('treatment_type', data.suggested_treatment_type?.map((item: string) => Number(item)));   
    }

    const ConfirmMutate = (data: IFormStep) => {
        Confirm.mutate({
            ...data,
            id: assessmentId,
            step: 3,
        });
    }

    const Confirm = useMutation(assessment.updateStepAssessment, {
        onSuccess: (data) => {
            setRefetchMedicationData(!refetchMedicationData);
            notify(
                'success',
                'ok',
                'Success',
                'Update Step Three',
            );
            queryClient.invalidateQueries(['assessmentStep']);  
        }
    });

    useEffect(()=>{
        setValue?.('treatment_type', !_.isEmpty(data?.treatment_type) ? data.treatment_type?.map((item: string) => Number(item)) : data.suggested_treatment_type?.map((item: string) => Number(item)));  
    },[assessmentId,data]);
    
    return (
        <div className="one-step">
            <ModalCondition 
                setFormVisable={setFormVisable}
                formVisable={formVisable}
                cardInfo={cardInfo}
            />
            <Row>
                <Col flex={3}>
                    <Row> <p className="one-step-header-left">Comorbidities</p> </Row>
                </Col>
                <Col flex={0.1}>
                    <div className="one-step-header-right">
                        <Row justify='space-between'>
                            {LableColor('No', '#00B050')}
                            {LableColor('Yes', '#d0f444')}
                            {LableColor('Empty', '#A6A6A6')}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="row-card-asess">
                {
                    data?.cards?.map((card: any, index: number) => {

                        return (
                            <Col span={8} key={index} className={`${(index + 1) % (24 / 8) != 0 ? 'card-asess-border' : 'col-card-asess'}`}>
                                <Card
                                    title={ 
                                        <Row justify="space-between">
                                            <Col> {card.title} </Col> 
                                            {card?.list?.[0]?.value === "NO" && <Col > <Plus className='pr-1' color={colors.primaryColor} size="25" /> </Col>}
                                        </Row>
                                        }
                                    size={"small"}
                                    className={`card-asess ${index < (data?.cards.length - (24 / 8)) ? 'card-asess-border-down' : ''}`}
                                    onClick={() => {
                                        card?.list?.[0]?.value === "NO" && setFormVisable(true)
                                        setCardInfo(card);
                                    }}
                                >
                                    {
                                        card.display_all === "no" ?
                                            (
                                                <p 
                                                    className="card-body-text"
                                                    style={{ 
                                                        color: card.list[0]?.status?.value == 0 && card.title == "Suggested Output" ? colors.dangerColor : colors.black,
                                                        fontWeight: card.list[0]?.status?.value == 0 && card.title == "Suggested Output" ? "bold" : undefined
                                                    }}
                                                >
                                                    {card.list[0] ? JSON.stringify(card.list[0]?.value).replace(/,/g, '\n').replace(/[{}"]/g, '') : ""}
                                                </p>
                                            )
                                            : (
                                                <Chart
                                                    chartType={card.graph == "line" ? "LineChart" : "ColumnChart"}
                                                    className="card-body-chart"
                                                    height="85px"
                                                    width="100%"
                                                    options={optionsChart(card)}
                                                    data={dataChart(card)}
                                                />
                                            )
                                    }
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className="row-asses-f">
                <Col span={3}> <p className="full-width" > Treatment </p> </Col>
                <Col span={7}>
                    <FormElement
                        {...sharedProps}
                        // value={data?.Phenotype !== 0 ? data?.Phenotype : data?.suggested_phenotype}
                        options={mapEnumToSelectValues(TreatmentTypes)}
                        // className="full-width"
                        placeholder="Allergic"
                        label=''
                        type='multiSelect'
                        name='treatment_type'
                        required
                    />
                </Col> 
                <Col span={4}>
                    <Button
                        className="full-width"
                        onClick={()=>{
                            OnSubmitResetValue();
                        }}
                    >
                        Reset to default
                    </Button>
                </Col>
                <Col span={6}>
                    <Button 
                        className="full-width" 
                        type="primary" 
                        onClick={handleSubmit(ConfirmMutate)}
                        loading={Confirm.isLoading}
                    >
                        Confirm Treatment
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
export default StepThree;