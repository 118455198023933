import React from 'react'; 
import { Col, Row } from 'antd';
import { useQuery } from 'react-query'; 
import { IFormProps } from '../../../models/form-props';
import FormElement from '../../../components/form-element';
import { mapEnumToSelectValues } from '../../../lib/utils';
import { Gender, UserTypes } from '../../../constants/enums';
import practitioner from '../../../lib/api/practitioner';

interface UserFormProps extends IFormProps { }

const PatientForm: React.FC<UserFormProps> = ({
    control,
    disabled,
    type
}) => { 
    
    const sharedProps = {
        control,
        disabled,
    };

    const getAllPractitioner = useQuery(['practitioner'], 
        () =>  practitioner.getAll({})
    ); 

    return ( 
        <Row>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='First Name'
                    type='input'
                    name='fname' 
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Last Name'
                    type='input'
                    name='lname' 
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Gender'
                    type='select'
                    name='gender'
                    options={mapEnumToSelectValues(Gender)} 
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Email'
                    type='input'
                    name='email' 
                    otherFormProps={{ type: 'email' }}
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Birth Date'
                    type='datePicker'
                    name='birth_date' 
                    required
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Practitioner'
                    type='multiSelect'
                    name='practitioners'
                    options={getAllPractitioner?.data?.results?.map((item:any)=> {
                        return {
                            label: item.fname + item.lname,
                            value: item.id
                        }
                    })}  
                    required 
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='City'
                    type='input'
                    name='city' 
                />
            </Col>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Weight'
                    type='input'
                    name='weight' 
                />
            </Col> 
        </Row> 
    );
};

export default PatientForm;
