import { Button, Col, Dropdown, MenuProps, Row } from "antd";
import { FunctionComponent, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import ActionModal from "./action-modal";

interface IActionRightProps {
    data: any;
}

const ActionRight: FunctionComponent<IActionRightProps> = ({ data }) => {
    const [isOpenAction, setIsOpenAction] = useState(false);

    const actionItems: MenuProps['items'] = [
        {
            label: 'New Action',
            key: '1',
            icon: '',
        },
        {
            label: 'View Action',
            key: '2',
            icon: '',
        },
    ];

    const contactItems: MenuProps['items'] = [
        {
            label: 'New Contact',
            key: '1',
            icon: '',
        },
        {
            label: 'View Contact',
            key: '2',
            icon: '',
        },
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key == '2')
            setIsOpenAction(true)
    };

    const actionMenuProps = {
        items: actionItems,
        onClick: handleMenuClick,
    };

    const contactMenuProps = {
        items: contactItems,
        // onClick: handleMenuClick,
    };

    return (
        <div className="section a-r">
            <Row gutter={[8, 16]} justify="center" align="middle" >
                <Col span={20}>
                    <Dropdown menu={actionMenuProps} placement="top">
                        <div className='w-full'>
                            <Button className='w-full' type="primary">
                                Action List
                                <DownOutlined />
                            </Button>
                        </div>
                    </Dropdown>
                </Col>
                <Col span={20}>
                    <Dropdown menu={contactMenuProps} placement="bottom">
                        <div className='w-full'>
                            <Button className='w-full' type="primary">
                                Contact List
                                <DownOutlined />
                            </Button>
                        </div>
                    </Dropdown>
                </Col>
            </Row>
            {isOpenAction && <ActionModal isOpenAction={isOpenAction} setIsOpenAction={setIsOpenAction} data={data} />}
        </div>
    );

}

export default ActionRight;