/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, DatePicker, Input, Row, Select } from 'antd';
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { HelpCircle, Plus } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import useCrud from '../../hooks/use-crud';
import { IFormOperation } from '../../models/form-operation';
import { IFormProps } from '../../models/form-props';
import { columnsInterface } from '../../models/table-columns';
import { EntityDto } from '../../models/entity';
import GeneralFormContainer, {
    IFormError,
} from '../general-form-containner';
import { notify } from '../notification';
import SideDrawer from '../side-drawer';
import Table from '../table';
import { setDocumnetTitle } from '../../lib/utils';
import { actionsColumn } from './default-actions';
import { IScaffoldCustomAction } from '../../models/custom-actions';
import { switchColumn } from './switch-column';
import { mapApiErrorToForm } from '../../lib/utils';
import Forbidden from '../forbidden';
import BasicModal from '../modal';
import Documentation from '../documentation';
import { KeyValueLabelDto } from '../../models/key-value-label';
import { SiMicrosoftexcel } from 'react-icons/si';
import colors from '../../constants/colors';

interface ScaffoldProps {
    // User Permissions
    mainPermissionName?: string;
    create?: boolean;
    update?: boolean;
    delete?: boolean;
    export?: boolean;
    goToOrder?: boolean;
    view?: boolean;
    details?: boolean;
    switchActivation?: boolean;
    switchActivationColumnKey?: string;
    switchActivationColumnDataSelector?: string;
    //TODO:add export to excel button and call api function (for later)
    exportToExcel?: boolean;
    exportToExcelFunc?: (data: any) => Promise<any>;
    // Crud api service functions
    createFunc?: (data: any) => Promise<any>;
    updateFunc?: (data: any) => Promise<any>;
    getAllFunc: (data: any) => Promise<any>;
    getFunc?: (data: any) => Promise<any>;
    deleteFunc?: (data: any) => Promise<any>;
    cancelFunc?: (data: any) => Promise<any>;

    //TODO :type for switchActivationFunc
    switchActivationFunc?: (data: EntityDto) => Promise<any>;
    // Mapper before subnmitting to create or update
    FromDataMapper?: (data: any) => Object;
    FormSubmitMapper?: (data: any) => any;
    //TODO: mapper for filling data
    //Gets passed with the pagination data
    getAllParams?: Object;
    //The data name is the caching key
    dataName?: string;
    headerTable?: React.ReactElement | string;
    formContent?: React.FunctionComponent<IFormProps>;
    tableColumns: columnsInterface[];
    filterOptions?: KeyValueLabelDto[];
    fullWidthFrom?: boolean;
    //Add custom actions to the table
    customActions?: IScaffoldCustomAction[]; 
    //Custom create onClick event
    customCreateAction?: (
        setFormVisable: (visable: boolean) => void,
    ) => void;
    setTile?: false | string;
    customOnRowClick?: (row: any) => void;
    formType?: 'side' | 'modal' | 'full' | 'full-page';
    otherFormProps?: Object;
    tableDataMapper?: (row: Object[]) => Object[];
    documentaionId?: number;
    modelWidth?: string;
    showModel?: boolean;
    deleteButtonLabel?: any;
    cancelButtonLabel?: any;
    onDelete?: any;
    onCancel?: any;
    defaultValues?: any;
    defaultSizePaination?: number;
    pageSizeOptions?: number[];
    width?: number;
}

const GeneralTable: FunctionComponent<ScaffoldProps> = (props) => {
    //? Queries---------------------------------------------
    const switchActivationMutation = useMutation(
        props.switchActivationFunc
            ? props.switchActivationFunc
            : () => new Promise(() => { }),
        {
            onSuccess: (data) => {
                notify(
                    'success',
                    'ok',
                    'success',
                    data[
                        props.switchActivationColumnDataSelector ??
                        'isActive'
                    ]
                        ? 'ActivationMsg'
                        : 'inactivationMsg',
                );
            },
            onError: (err: any) => {
                notify(
                    'danger',
                    'error',
                    'someErrorHappend',
                    err.response.data.error.message,
                );
            },
            onSettled: () => {
                crudHook.getAllHook.refetch();
            },
        },
    );

    const ExcelReportMutation = useMutation(
        props.exportToExcelFunc
            ? props.exportToExcelFunc
            : () => new Promise(() => { }),
        {
            onSuccess: (data) => {
                const element = document.createElement("a");
                element.href = data?.url || data;
                element.click();
                setLoadExportExcel(false);
            },
            onError: () => {
                setLoadExportExcel(false);
            }
        }
    );

    //? Contexts ----------------------------------------------
    // const authContext = useContext<IAuthContext>(AuthContext);

    //? State -------------------------------------------------
    const [formVisable, setFormVisable] = useState<boolean>(false);
    const [getAllParams, setGetAllparams] = useState<any>({});

    const [getAllFilterParams, setAllSearchParams] = useState<Record<string, string>>({});
    const [columns, setColumns] = useState(props.tableColumns);
    const [errors, setErrors] = useState<IFormError[]>([]);
    // Documentation
    const [previewVisible, setpreviewVisible] = useState<boolean>(false);
    const handleCancel = () => setpreviewVisible(false);
    const handleClick = () => setpreviewVisible(true);

    // ************************************************

    // ***********************************************
    //TODO: get the permissions from the api and override them with the props
    const [permissions, setPermissions] = useState<{
        [p: string]: boolean | undefined;
    }>({
        Create: props.create,
        Update: props.update,
        View: props.details,
        Delete: props.delete,
        ExportExcel: props.export,
        GoToOrder: props.goToOrder,
        SwitchActivation: props.switchActivation,
    });

    const [type, setType] = useState<IFormOperation>('Create');
    const [id, setId] = useState<any>(0);

    //? events -------------------------------------------------
    const onCreateRow = () => { 
        setType('Create'); 
        setFormVisable(true);
        setId(undefined);
    };


    const [deleteVisible, setdeleteVisible] = useState<boolean>(false);
    const [cancelVisible, setCancelVisible] = useState<boolean>(false);
    const [loadExportExcel, setLoadExportExcel] = useState<boolean>(false);

    const onDeleteRow = (id: string) => {
        setdeleteVisible(true);
        setId(id);
    };

    const onDeleteHandle = () => {
        crudHook.deleteMutation.mutate({ id });
        setdeleteVisible(false);
    };
    const deletehandleCancel = () => {
        setdeleteVisible(false);
    };
    // Cancel
    const onCancelRow = (id: string) => {
        setCancelVisible(true);
        setId(Number(id));
    };

    const onCancelHandle = () => { 
        crudHook.cancelMutation.mutate({ id });
        setCancelVisible(false);
    };

    const cancelhandleCancel = () => {
        setCancelVisible(false);
    };

    // go to order 
    const onGoToOrder = (row: any) => {
        const urlPlane = `/auth/patient/${row?.patientId}/${row?.case?.value}/plan/${row?.plan?.value}`;
        window.open(urlPlane);
    }


    const onDetailsRow = (id: string) => {
        setType('Details');
        setId(Number(id));
        setFormVisable(true);
    };

    const onAnalysisDetailsRow = (id: string) => {
        setType('AnalysisDetails');
        setId(Number(id));
        setFormVisable(true);
    };

    const onUpdateRow = (id: string) => {
        setType('Update');
        setId(id);
        setFormVisable(true);
    };

    const onSearch = (search: string) => {
        // setGetAllparams({ ...getAllParams, Keyword: v });
        setAllSearchParams({ search: search });
    };
    // const debouncedSearch = _.debounce(onSearch, debouncePeriod);
    const toggleForm = () => {
        setFormVisable(!formVisable);
        crudHook.getAllHook.refetch();
    };
    const onSwitchChange = (id: number) => {
        switchActivationMutation.mutate({ id });
    };
    const onExcelReport = () => {
        ExcelReportMutation.mutate({ ...getAllFilterParams, MaxResultCount: crudHook.getAllHook.data?.totalCount });
    };

    //? Hooks ------------------------------------------------


    useEffect(() => {
        // if (props.mainPermissionName && !authContext.loading) {

        const newTableColumns = props.tableColumns.map((c) => ({
            ...c,
            onCellClick: (row: any) => {
                props.customOnRowClick
                    ? props.customOnRowClick(row)
                    : onUpdateRow(row.id);
            },
        }));

        if (props.switchActivationFunc && props.switchActivation) {
            setColumns([
                ...newTableColumns,
                switchColumn(
                    onSwitchChange,
                    switchActivationMutation.isLoading,
                    props.switchActivationColumnKey,
                    props.switchActivationColumnDataSelector,
                ),
                actionsColumn(
                    {
                        onCreateRow,
                        onDeleteRow,
                        onDetailsRow,
                        onAnalysisDetailsRow,
                        onUpdateRow,
                        onGoToOrder
                    },
                    props.dataName ?? ' ',
                    permissions,
                    props.customActions,
                    () => crudHook.getAllHook.refetch(),
                ),
            ]);

        } else if (
            permissions.Create ||
            permissions.View ||
            permissions.Delete ||
            permissions.Update ||
            permissions.Approve ||
            permissions.Reject ||
            permissions.ExportExcel ||
            props.customActions
        ) {
            setColumns([
                ...newTableColumns,
                actionsColumn(
                    {
                        onCreateRow,
                        onDeleteRow,
                        onDetailsRow,
                        onAnalysisDetailsRow,
                        onUpdateRow,
                        onGoToOrder
                    },
                    props.dataName ?? ' ',
                    permissions,
                    props.customActions,
                    () => crudHook.getAllHook.refetch(),
                ),
            ]);
        }
        // }
    }, []);

    useEffect(() => {
        if (props.setTile === false) return;

        const title = props.setTile ? props.setTile : props.dataName ?? ' '

        setDocumnetTitle(title);
    }, [props.dataName]);

    const crudHook = useCrud(
        {
            create: props.createFunc ? props.createFunc : () => new Promise(() => { }),
            update: props.updateFunc ? props.updateFunc : () => new Promise(() => { }),
            delete: props.deleteFunc ? props.deleteFunc : () => new Promise(() => { }),
            cancel: props.cancelFunc ? props.cancelFunc : () => new Promise(() => { }),
            getAllFunc: props.getAllFunc,
        },
        {
            dataName: props.dataName ?? ' ',
            getAllDefaultParams: {
                ...props.getAllParams,
                ...getAllParams,
                ...getAllFilterParams,
            },
            getAllEnabled: true,
        },
        {
            onCreateSuccess: () => setFormVisable(false),
            onUpdateSuccess: () => setFormVisable(false),
            onCreateError: () => (error: any) => {
                alert(error)
                setErrors(mapApiErrorToForm(error.response?.data));
            },
            onUpdateError: () => (error: any) =>
                setErrors(mapApiErrorToForm(error.response?.data)),
        },
    );

    const deleteMutation = useMutation(props.onDelete, {
        onSuccess: (data) => {
            notify('success', 'ok', 'cancelMsg');
            setFormVisable(false);
        }, onError: (err: any) => {
            notify(
                'danger',
                'error',
                'someErrorHappend',
                err.response.data.error.message,
            );
        },
    });

    // cancel Mutation
    const cancelMutation = useMutation(props.onCancel, {
        onSuccess: (data) => {
            notify('success', 'ok', 'stopProcessingMsg');
            setFormVisable(false);
        }, onError: (err: any) => {
            notify(
                'danger',
                'error',
                'someErrorHappend',
                err.response.data.error.message,
            );
        },
    });

    // const createChatMutation = useMutation(message.messageCreate, {
    //     onSuccess: () => {
    //         notify('success', 'ok', 'createMsg');
    //         queryClient.invalidateQueries('myMessages');
    //         setFormVisable(false);
    //     },
    // });

    //? UI elements -------------------------------------------------------------------------
    const formContent = () => (
        <GeneralFormContainer
            type={type}
            content={props.formContent}
            actionLoading={
                crudHook.updateMutation.isLoading ||
                crudHook.createMutation.isLoading
            }
            errors={errors}
            dataName={props.dataName ?? ' '}
            selectedId={id}
            toggleContent={toggleForm}
            getFunc={props.getFunc}
            detailsMapper={props.FromDataMapper}
            otherFormProps={props.otherFormProps}
            onSubmit={(data: any) => {
                crudHook.getAllHook.refetch(); 
                type === 'Create'
                    ? crudHook.createMutation.mutate(
                        props.FormSubmitMapper
                            ? props.FormSubmitMapper(data)
                            : data,
                    )
                    : crudHook.updateMutation.mutate(
                        props.FormSubmitMapper
                            ? props.FormSubmitMapper({
                                id,
                                ...data,
                            })
                            : { id, ...data },
                    );
            }}
            deleteButtonLabel={props.deleteButtonLabel}
            cancelButtonLabel={props.cancelButtonLabel}
            onDelete={(e: any) => {
                e.preventDefault();
                deleteMutation.mutate(id);
                crudHook.getAllHook.refetch();
            }
            }
            onCancel={(e: any) => {

                e.preventDefault();
                onCancelRow(id)
                cancelMutation.mutate(id);
                crudHook.getAllHook.refetch();
            }
            }
            defaultValues={props.defaultValues}
            disableDeleteButton={type !== 'Update'}
        />
    );

    const renderForm = (): JSX.Element => {
        if (!formVisable) return <></>;
        switch (props.formType) {
            case 'side':
                return (
                    <SideDrawer
                        width={props.fullWidthFrom ? '101vw' : undefined}
                        visible={formVisable}
                        onClose={toggleForm}
                        header={
                            <>
                                <FormattedMessage id={type} />{' '}
                                <FormattedMessage id={props.dataName} />
                            </>
                        }
                    >
                        {formContent()}
                    </SideDrawer>
                );

            default:
            case 'modal':
                return (
                    <>
                        <BasicModal
                            header={
                                <h2 className='text-primary'>
                                    <FormattedMessage id={type} />{' '}
                                    <FormattedMessage
                                        id={props.dataName}
                                    />
                                </h2>
                            }
                            isOpen={formVisable}
                            closable
                            handleCancel={toggleForm}
                            afterClose={toggleForm}
                            width={props.modelWidth ? props.modelWidth : '60vw'}
                            footer={false}
                            content={formContent()}
                        />
                    </>
                );
            case 'full':
                return (
                    <Card
                        headStyle={{ backgroundColor: colors.primaryExtraLight }}
                        bordered={false}
                        title={
                            <>
                                <h2 className='text-primary'>
                                    <FormattedMessage id={type} />{' '}
                                    <FormattedMessage id={props.dataName} />
                                </h2>
                            </>
                        }
                    >
                        {formContent()}
                    </Card>
                );
            case 'full-page':
                return formContent();
        }
    };
    // if (permissions.View === false) {
    //     return (
    //         <Forbidden
    //             permissions={permissions}
    //             permissionName={props.mainPermissionName}
    //         ></Forbidden>
    //     );
    // }
    if (
        formVisable &&
        (props.formType === 'full' || props.formType === 'full-page')
    ) {
        return renderForm();
    }

    return (
        <>
            <Row justify="center">
                <Col>
                    <h2 className='mb-4'> {props.headerTable}</h2>
                </Col>
            </Row>
            {renderForm()}
            <Card className='card-table'>
                {props.dataName && <Row>
                    {/* <Col>
                        <h2 className='mb-4 ml-2' id={props.dataName}>
                            <FormattedMessage id={`${props.dataName}s`} />
                        </h2>
                    </Col> */}
                    <Col>
                        {props.documentaionId && (
                            <HelpCircle onClick={() => handleClick()} />
                        )}
                        <BasicModal
                            isOpen={previewVisible}
                            footer={false}
                            handleCancel={() => handleCancel()}
                            content={
                                <>
                                    <Documentation
                                        id={props.documentaionId}
                                    />
                                </>
                            }
                        />
                    </Col>
                </Row>}

                <div>
                    <Table
                        onChange={(pagination, filters, sorter: any) => {
                            setGetAllparams((previousState: any) => ({
                                ...getAllParams,
                                Sorting: sorter.columnKey
                                    ? `${sorter.columnKey} ${sorter.order === 'descend'
                                        ? 'DESC'
                                        : 'ASC'
                                    }`
                                    : undefined,
                                MaxResultCount:
                                    pagination?.pageSize ?? previousState,
                                SkipCount:
                                    (pagination?.current
                                        ? pagination?.current - 1
                                        : 0) * (pagination?.pageSize ?? 0),
                            }));
                        }}
                        // width={props?.width}
                        defaultPageSize={props?.defaultSizePaination}
                        pageSizeOptions={props?.pageSizeOptions}
                        total={crudHook.getAllHook.data?.totalCount}
                        dataName={props.dataName}
                        onSearchchange={onSearch}
                        loading={crudHook.getAllHook.isLoading}
                        columns={columns}
                        filterOptions={props.filterOptions || []}
                        data={
                            props.tableDataMapper
                                ? props.tableDataMapper(
                                    crudHook.getAllHook?.data?.results || crudHook.getAllHook?.data,
                                )
                                : crudHook.getAllHook?.data?.results || crudHook.getAllHook?.data
                        }
                        tableHeaderActions={
                            (props.exportToExcelFunc || props.create) ?
                            <Row className='w-full h-full content-end justify-end px-2 items-center'>
                                {props.exportToExcelFunc && (
                                    <Col offset={1}>
                                        <Button
                                            icon={
                                                <SiMicrosoftexcel size={25} className='pr-2' />
                                            }
                                            type='primary'
                                            className='flex mb-3'
                                            onClick={() => {
                                                setLoadExportExcel(true);
                                                onExcelReport()
                                            }}
                                            loading={loadExportExcel}
                                        >
                                            <FormattedMessage id='export' />
                                        </Button>
                                    </Col>
                                )}
                                {props.create && (
                                    <Col offset={1}>
                                        <Button
                                            icon={
                                                <Plus className='pr-1' />
                                            }
                                            type='primary'
                                            className='flex mb-3'
                                            // Inside the onClick event handler
                                            onClick={() => {
                                                props.customCreateAction
                                                    ? props.customCreateAction(setFormVisable)
                                                    : onCreateRow();
                                            }}
                                            loading={
                                                crudHook.createMutation
                                                    .isLoading
                                            }
                                        >
                                            <FormattedMessage id='Create' />
                                            {/* &nbsp;
                                            <FormattedMessage
                                                id={props.dataName}
                                            /> */}
                                        </Button>
                                    </Col>
                                )}
                            </Row> 
                            :
                            undefined
                        }
                    />
                </div>
            </Card>

            <BasicModal
                header={
                    <>
                        <FormattedMessage id={'Confirm Delete'} />
                    </>
                }
                headerType='error'
                content={
                    <>
                        <Row>
                            <Col span={8} offset={4}>
                                <Button
                                    type='primary'
                                    className='savebtn'
                                    onClick={onDeleteHandle}
                                    style={{
                                        cursor:
                                            Object.keys(errors).length !==
                                                0
                                                ? 'not-allowed'
                                                : 'pointer',
                                    }}
                                >
                                    <FormattedMessage id={'OK'} />
                                </Button>
                            </Col>
                            <Col span={8} offset={4}>
                                <Button
                                    type='default'
                                    className='savebtn'
                                    onClick={deletehandleCancel}
                                    style={{
                                        cursor:
                                            Object.keys(errors).length !==
                                                0
                                                ? 'not-allowed'
                                                : 'pointer',
                                    }}
                                >
                                    <FormattedMessage id={'Cancel'} />
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                isOpen={deleteVisible}
                footer={false}
                width='35vw'
                handleCancel={() => {
                    setdeleteVisible(false);
                }}
            />

            {/* Cancel Modal confirmation */}
            <BasicModal
                header={
                    <>
                        <FormattedMessage id={'confirmdeletionStopProcessing'} />
                    </>
                }
                headerType='error'
                content={
                    <>
                        <Row>
                            <Col span={8} offset={4}>
                                <Button
                                    type='primary'
                                    className='savebtn'
                                    onClick={onCancelHandle}
                                    style={{
                                        cursor:
                                            Object.keys(errors).length !==
                                                0
                                                ? 'not-allowed'
                                                : 'pointer',
                                    }}
                                >
                                    <FormattedMessage id={'affirmation'} />
                                </Button>
                            </Col>
                            <Col span={8} offset={4}>
                                <Button
                                    type='default'
                                    className='savebtn'
                                    onClick={cancelhandleCancel}
                                    style={{
                                        cursor:
                                            Object.keys(errors).length !==
                                                0
                                                ? 'not-allowed'
                                                : 'pointer',
                                    }}
                                >
                                    <FormattedMessage id={'cancel'} />
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                isOpen={cancelVisible}
                footer={false}
                width='35vw'
                handleCancel={() => {
                    setCancelVisible(false);
                }}
            />
        </>
    );
};

export default GeneralTable;
