import { Button, Form, Input, Typography } from 'antd';
import { useCookies } from 'react-cookie';
import '../../styles/login/index.scss';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import account from '../../lib/api/account';
import { notify } from '../../components/notification';
import FormElement from '../../components/form-element';
import { inputsPatterns } from '../../constants/inputs-patterns';
import { LoginRequest } from '../../models/login_request';
import cpiIcon from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

export default function Login() {

    const { Text, Link } = Typography;
    const [loading, setLoading] = useState<boolean>();
    const [cookies, setCookie, removeCookie] = useCookies();
    let navigate = useNavigate();

    const { control, reset, handleSubmit } = useForm<LoginRequest>({
        mode: "onBlur",
        shouldFocusError: true,
    });

    const sharedProps = {
        control
    };

    const onFinish = async (values: LoginRequest) => {

        setLoading(true);
        const result = await account.login(values).catch((err: any) => {
            setLoading(false);
            notify(
                'danger',
                'error',
                'Login Feild!',
                err.response?.data?.message,
            );
            return;
        });
        if (result) {
            navigate("/auth/patients");
            setCookie('CPI_TOKEN', result?.access, {
                path: '/',
            });
        }
        setLoading(false);
    };

    return (
        <div className='card-container'>
            <div className="flex justify-center">
                <img
                    src={cpiIcon}
                    alt="hyper"
                    height={200}
                    width={200}
                    className="mb-3 flex self-center bg-red"
                />
            </div>
            <div className='d-form'>
                <Form
                    name="basic"
                    layout="vertical"
                    className='form-login'
                    style={{ minWidth: 400 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <FormElement
                        {...sharedProps}
                        placeholder="Enter Username"
                        label='User name'
                        name='username'
                        type='input'
                        required
                    />

                    <FormElement
                        {...sharedProps}
                        placeholder="Enter Password"
                        label='Password'
                        name='password'
                        type='input'
                        otherFormProps={{ type: 'password' }}
                        pattern={inputsPatterns.password}
                        required
                    />

                    <Button type="primary"
                        htmlType="submit"
                        className='mt-12 ant-login-btn'
                        loading={loading}
                        onClick={handleSubmit(onFinish)}
                    >
                        Submit
                    </Button>
                    {/* <Text className='text-create-account'>
                        Don't have an account?{' '}
                        <Link href="/register">Create Now!</Link>
                    </Text> */}
                </Form>
            </div>
        </div>
    )
}
