import { useParams } from "react-router-dom"; 
import { drugPolicyColumns } from "./data";
import DrugPolicyForm from "./forms";
import GeneralTable from "../../../components/general-table"; 
import drugPolicy from "../../../lib/api/drug-policy";
import { attachmentMapper } from "../../../lib/utils";
import { BiologicTypes } from "../../../constants/enums";

const DrugPolicyPage = () => {
    const { id } = useParams();

    return (
        <GeneralTable
            dataName="Drug Policy"
            headerTable="Payer Drug Policies"
            getAllFunc={()=>drugPolicy.getAll({payer_id: Number(id)})}
            getFunc={drugPolicy.Get}
            delete
            deleteFunc={drugPolicy.Delete}
            create
            createFunc={drugPolicy.Create}
            update
            updateFunc={drugPolicy.Update}
            formContent={DrugPolicyForm}   
            tableColumns={drugPolicyColumns}  
            customOnRowClick={()=>{}}
            tableDataMapper={(rows) => {
                return rows?.map((row: any, index: number) => {
                    return {
                        ...row, 
                        biologic: BiologicTypes[row?.biologic] 
                    };
                });
            }}
            FromDataMapper={(data: any) => {
                return {
                    ...data,   
                };
            }}  
            FormSubmitMapper={(data: any) => {
                return {
                    ...data, 
                    ...attachmentMapper(['attachment'], data, false),
                    payer: Number(id)
                };
            }}  
        />
    );
}
export default DrugPolicyPage;