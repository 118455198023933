import React, { useState } from 'react'; 
import { Col, Row } from 'antd'; 
import { IFormProps } from '../../../../../../models/form-props';
import FormElement from '../../../../../../components/form-element'; 
import observation from '../../../../../../lib/api/observation';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom'; 
import { mapEnumToSelectValues } from '../../../../../../lib/utils';
import { CategoryTypeEnum } from '../../../../../../constants/enums';
import _ from 'lodash';

interface ObservationFormProps extends IFormProps { }

const ObservationForm: React.FC<ObservationFormProps> = ({
    control,
    disabled,
    data
}) => { 
    const { patientId } = useParams();
    const [type, setType] = useState(data?.category);
    const [code, setCode] = useState(data?.code);
    const [observationItem, setObservationItem] = useState<any>();
    
    const sharedProps = {
        control,
        disabled,
    }; 

    const getAllLiteObservation = useQuery(['assessment step', type], 
        () =>  observation.getAllLite({ 
            patient_id: Number(patientId),
            category: type 
        }),
        {   
            enabled: (!!type || type == 0),
            onSuccess(data) {
                setObservationItem(data.find((element:any) =>  element.value_code === code));
            },
        }
    ); 

    return ( 
        <Row>
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Category'
                    type='select'
                    name='category'
                    options={mapEnumToSelectValues(CategoryTypeEnum)} 
                    onChange={(value)=>  setType(value) }
                    required
                />
            </Col> 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Name'
                    type='select'
                    name='code'
                    otherFormProps={{loading : getAllLiteObservation.isLoading}}
                    options={getAllLiteObservation?.data?.map((item:any)=>({
                        ...item,
                        label: item.label_code,
                        value: item.value_code
                    }))}  
                    onChange={(value, option)=> {
                        setCode(value); 
                        setObservationItem(option);
                    }} 
                    required
                />
            </Col> 
            {observationItem?.value_is_required == 1 && <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Value'
                    type='number'
                    name='value' 
                    required
                />
            </Col> 
            }
            {!_.isEmpty(observationItem?.units) && 
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Unit'
                    type='select'
                    name='unit'
                    otherFormProps={{loading : getAllLiteObservation.isLoading}}
                    options={observationItem?.units
                            ?.map((item:string)=>({
                                label: item,
                                value: item
                            }))
                    } 
                    defaultValue={observationItem?.units[0]}
                />
            </Col>}
            <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='Issued'
                    type='datePicker'
                    name='issued_at'
                    required
                />
            </Col>
            {observationItem?.value_code == "366874008" && <Col span={12}>
                <FormElement
                    {...sharedProps}
                    label='hospital admission, ER or rescue OCS'
                    type='checkbox'
                    name='with_er'
                    required
                />
            </Col>}
        </Row> 
    );
};

export default ObservationForm;
