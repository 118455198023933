import { Row } from 'antd';
import {
    FunctionComponent,
    Suspense,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import PageLayout from '../page-layout';
import LoaderApp from '../loader';
interface AuthenticatedRouteProps { }

const AuthenticatedRoute: FunctionComponent<
    AuthenticatedRouteProps
> = () => {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    // const authContext = useContext(AuthContext);
    const [canLogin, setCanlogin] = useState(true);
    // useQuery(
    //     ['checkToken', cookies.CPI_TOKEN],
    //     () =>
    //         user.userValidateJwtToken({ accessToken: cookies.CPI_TOKEN }),
    //     {
    //         enabled: cookies.CPI_TOKEN !== undefined,
    //         onSuccess(data: boolean): void {
    //             setCanlogin(data);
    //         },
    //         onError: async (data: boolean) => {
    //             setCanlogin(false);
    //             // await authContext.actions.logout();
    //             navigate('/login');
    //         },
    //         refetchOnWindowFocus: false,
    //     },
    // );
    useEffect(() => {
        !cookies.CPI_TOKEN && navigate('/login');
    }, [cookies.CPI_TOKEN, navigate]);

    return (
        <>
            {canLogin ? (
                <>
                    <PageLayout>
                        <Suspense
                            fallback={
                                <Row
                                    justify='center'
                                    align='middle'
                                    className='w-full h-screen'
                                >
                                    <LoaderApp />
                                </Row>
                            }
                        >
                            <Outlet />
                        </Suspense>
                    </PageLayout>
                </>
            ) : (
                <>
                    <Row
                        justify='center'
                        align='middle'
                        className='w-full h-screen'
                    >
                        <LoaderApp />
                    </Row>{' '}
                </>
            )}
        </>
    );
};

export default AuthenticatedRoute;
