import { BiologicTypes, OutcomeChoices, RequestTypeEnum, StatusTypes } from "../../constants/enums";
import DetailsIcon from '../../assets/icons/patient-table/Details.svg';
import { currentIndexStepPaAtom, paAtom } from "../../atoms/atoms";
import GeneralTable from '../../components/general-table';
import { patientColumns } from './data';
import { useRecoilState } from "recoil";
import pa from "../../lib/api/pa";
import { useNavigate } from "react-router-dom";

export default function PaPage() {
    const [_, setPaAtom] = useRecoilState(paAtom);
    const [_c, setCurrentIndexStep] = useRecoilState(currentIndexStepPaAtom);
    const navigate = useNavigate();

    return (
        <GeneralTable
            headerTable="Prior Authorization Requests"
            dataName="PA"
            getAllFunc={pa.getAllPa}
            delete
            deleteFunc={pa.paDelete}
            tableColumns={patientColumns}
            customOnRowClick={() => { }}
            tableDataMapper={(rows) => {  
                return rows?.map((row: any, index: number) => {
                    return {
                        ...row,
                        biologic: BiologicTypes[row?.biologic],
                        status: StatusTypes[row?.status],
                        outcome: OutcomeChoices[row?.outcome],
                        request_type: RequestTypeEnum[row?.request_type],
                    };
                });
            }}
            customActions={[
                {
                    Icon: <img src={DetailsIcon} className='icon-button-table' />,
                    cb: (row) => {
                        setPaAtom(row);
                        setCurrentIndexStep(Number(StatusTypes[row?.status]))
                        navigate(`/auth/pa/${row.id}/${row.biologic}/`);
                    },
                    tooltip: 'PA Dashboard',
                },
            ]}
        />
    )
}
