import { FunctionComponent } from 'react';
import { ValueLabelDto } from '../../models/value_label';
import { inputsPatterns } from '../../constants/inputs-patterns';
import { IInputPattern } from '../../models/input-pattern';
import Checkbox from '../checkbox';
import DatePicker from '../date-picker'; 
import Input from '../input';
import InputNumber from '../numeric-input';
import Select from '../select';
import TextArea from '../textarea'; 
import TimePicker from '../time-picker';
import { Col, Row } from 'antd';
import SelectWithPage from '../select-with-page';
import { DefaultOptionType } from 'antd/es/select';
import { useIntl } from 'react-intl';
import UploadFile from '../uploadFile';

interface FormElementProps {
    type:
    | 'input'
    | 'textarea'
    | 'select'
    | 'selectWithPage'
    | 'multiSelect'
    | 'editor'
    | 'number'
    | 'decimal'
    | 'checkbox'
    | 'customDatePicker'
    | 'datePicker'
    | 'datePickerWithTime'
    | 'timePicker'
    | 'dropzone'
    | 'multiUploadFile' 
    | 'textNextToInput'
    | 'uploadFile';
    name: string;
    control: any;
    placeholder?: string;
    label: string;
    labelSide?: string;
    disabled?: boolean;
    options?: ValueLabelDto[] | undefined;
    //Validation Props
    min?: number;
    max?: number;
    required?: boolean;
    pattern?: IInputPattern;
    patternMsg?: string;
    isChecked?: boolean;
    otherFormProps?: Object;
    onChange?: (value: any, option?: DefaultOptionType) => void;
    defaultValue?: any;
    textLabel?: string | any;
    getAllFunc?: (data: any) => Promise<any>; 
    optionDataMapper?: (row: Object[]) => Object[];
}

const FormElement: FunctionComponent<FormElementProps> = ({
    type,
    name,
    control,
    placeholder,
    label,
    labelSide,
    disabled,
    options,
    required,
    min,
    max,
    pattern,
    isChecked,
    otherFormProps,
    defaultValue,
    onChange,
    textLabel,
    getAllFunc,
    optionDataMapper
}) => {
    const intl = useIntl();

    const rules = {
        required: {
            value: required,
            message:  'Field is required',
        },
        maxLength: {
            value: max,
            message: 'The number characters must be exactly less than ' + max ,
        },
        minLength: {
            value: min,
            message: 'The number characters must be exactly greater than ' + min ,
        },
        min: {
            value: min,
            message:  'Number must be exactly less than '  + min
        },

        max: {
            value: max,
            message:'Number must be exactly greater than '+ max,
        },
        pattern: {
            value: pattern?.value,
            message: pattern?.msgKey ?? inputsPatterns.invalid.msgKey,
        },
    };
    const sharedProps = {
        control,
        disabled,
        name,
        placeholder,
        label,
        labelSide,
        rules,
        onChange,
        defaultValue
    };
    switch (type) {
        case 'input':
            return <Input {...sharedProps} {...otherFormProps} />;
        case 'textarea':
            return <TextArea {...sharedProps} {...otherFormProps} />;
        case 'number':
            return <InputNumber {...sharedProps} {...otherFormProps} />;
        case 'decimal':
            return <InputNumber step="0.01" {...sharedProps} {...otherFormProps} />;
        case 'select':
            return (
                <Select
                    options={options}
                    {...sharedProps}
                    {...otherFormProps} />
            );
        case 'selectWithPage':
            return (
                <SelectWithPage
                    options={options}
                    getAllFunc={getAllFunc}
                    optionDataMapper={optionDataMapper}
                    {...sharedProps}
                    {...otherFormProps} 
                />
            );
        case 'multiSelect':
            return (
                <Select
                    multiple
                    options={options}
                    {...sharedProps}
                    {...otherFormProps} />
            );
        case 'checkbox':
            return <Checkbox {...sharedProps} {...otherFormProps} isChecked={isChecked} />;
        case 'uploadFile':
            return <UploadFile {...sharedProps} {...otherFormProps} maxCount={1}/>;
        case 'multiUploadFile':
            return (
                <UploadFile multiple {...sharedProps} {...otherFormProps} />
            );
        case 'datePicker':
            return <DatePicker {...sharedProps} {...otherFormProps} />;
        case 'datePickerWithTime':
            return <DatePicker showTime {...sharedProps} {...otherFormProps} />;
        case 'timePicker':
            return <TimePicker {...sharedProps} {...otherFormProps} />;
        case 'textNextToInput':
            return <>
                <Row>
                    <Col span={18}>
                        <Input {...sharedProps} {...otherFormProps} />

                    </Col>

                    <Col span={3} style={{ margin: '4rem' }}>
                        {textLabel}
                    </Col>
                </Row>
            </>;
        default:
            return <></>;
    }
};

export default FormElement;
