export enum Gender {
  "Male" = 0,
  "Female" = 1,
}

export enum SourceTypes {
  "CPI" = 0,
  "FHIR" = 1,
}

export enum AsthmaTypes {
  "Severe Persistent" = 0,
  "Moderate Persistent" = 1,
  "Mild Persistent" = 2,
  "Mild Intermittent" = 3,
  "Other" = 4,
}

export enum ControlType {
  "Well Controlled" = 0,
  "Not Well Controlled" = 1,
  "Poorly Controlled" = 2,
  "Other" = 3,
}

export enum PhenoType {
  "Non-T2" = 0,
  "Allergic" = 1,
  "Nonallergic" = 2,
}

export enum TreatmentTypes {
  "Anti-IgE" = 0,
  "Anti-IL4" = 1,
  "Anti-IL5" = 2,
  "Anti-TSLP" = 3,
}

export enum BiologicTypes {
  "Xolair" = 0,
  "Nucala" = 1,
  "Cinqair" = 2,
  "Fasenra" = 3,
  "Dupixent" = 4,
  "Tezspire" = 5,
}

export enum StatusTypes {
  "New" = 0,
  "Info" = 1,
  "Submitted" = 2,
  "Respone" = 3,
  "Completed" = 4,
}

export enum StatusChoices {
  "New" = 0,
  "Submitted" = 1,
  "Completed" = 2,
}

export enum RequestChoices {
  "Initial" = 0,
  "Appeal" = 1,
  "PeerToPeer" = 2,
  "ExternalReview" = 3,
  "FormalGrievance" = 4,
}

export enum OutcomeChoices {
  "Approved" = 0,
  "Denied" = 1,
}

export enum CoverageChoices {
  "Medical" = 0,
  "Pharmacy" = 1,
}

export enum BiologicList {
  "Xolair" = 0,
  "Nucala" = 1,
  "Cinqair" = 2,
  "Fasenra" = 3,
  "Dupixent" = 4,
  "Tezspire" = 5,
}

export enum PlanChoices {
  "Commercial" = 0,
  "Medicare" = 1,
  "Medicaid" = 2,
}

export enum AuthorizationChoices {
  "Buy & Bill" = 0,
  "Specialty Pharmacy" = 1,
}

export enum ServiceChoices {
  "Availity" = 0,
  "Covermymeds" = 1,
  "UHCprovider" = 2,
  "UMR" = 3,
  "CignaPromptPA" = 4,
  "Fax" = 5,
}

export enum PayerEnum {
  "Aetna" = 2,
}

export enum StepAssesEnum {
  "Severity" = 1,
  "Phenotype" = 2,
  "Treatment Type" = 3,
  "Accessibility Scores" = 4,
  "PA Charts" = 5,
}

export enum RequestTypeEnum {
  "Initial" = 0,
  "Renewal" = 1,
}

export enum CategoryTypeEnum {
  "Laboratory" = 0,
  "Symptoms" = 1,
  "Procedure" = 2,
  "Exam" = 3,
  "Survey" = 4,
  "Findings" = 5,
  "Therapy" = 6,
  "Social History" = 7
}

export enum UserTypes {
  "Admin" = 0,
  "Clinic Admin" = 1,
  "Practitioner" = 2,
  "Back Office" = 3,
  "Front Office" = 4
}

export enum PayerTypes {
  "Private/Commercial" = 0,
  "Medicare" = 1,
  "Medicaid" = 2,
  "Other" = 3 
}

export enum PlanType { 
  "HMO" = 0,
  "PPO" = 1,
  "EPO" = 2,
  "POS" = 3,
  "Other" = 4
}

export enum AdministeredType { 
  "Self-Administered" = 0,
  "HCP-Administered" = 1, 
}

export enum BenefitType { 
  "Medical" = 0,
  "Pharmacy" = 1, 
  "Both" = 2, 
}