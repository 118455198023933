import { useParams } from "react-router-dom";
import GeneralTable from "../../../../../components/general-table"; 
import medication from "../../../../../lib/api/medication";
import { medicationColumns } from "./data";
import MedicationForm from "./forms";

const Medication = () => {
    const { patientId } = useParams();

    return (
        <GeneralTable
            dataName="Medication"
            getAllFunc={()=>medication.getAll({patient_id: Number(patientId)})}
            getFunc={medication.Get} 
            delete
            deleteFunc={medication.Delete}
            create
            createFunc={medication.Create}
            formContent={MedicationForm}     
            update
            updateFunc={medication.Update}
            tableColumns={medicationColumns}  
            FormSubmitMapper={(data: any) => {
                return {
                    ...data,
                    patient: patientId
                };
            }} 
        />
    );
}
export default Medication;