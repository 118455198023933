import { UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Row } from 'antd';
import { FunctionComponent } from 'react';
import usenameIocn from '../../../assets/icons/patient-table/assesment/user-name.svg';
import birthdayIocn from '../../../assets/icons/patient-table/assesment/birthday.svg';
import group2052Iocn from '../../../assets/icons/patient-table/assesment/group2052.svg';
import moment from 'moment';
import { Gender } from '../../../constants/enums';
import { dateFormatMonth } from '../../../constants';
import assessment from '../../../lib/api/assessment';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import LoaderApp from '../../../components/loader';

const PatientInfo: FunctionComponent<any> = ({ getAssessment, getClarityNote }) => {
    var assessments = getAssessment?.data;
    const { patientId } = useParams(); 

    return (
        <Row style={{ alignItems: 'center' }} className='patient-information'>
            <Col className='col-avatar'>`
                <Avatar className='antd-avatar' size={80} icon={<UserOutlined />} />`
            </Col>
            <Col span={6} className='col-row-info'>
                <LoaderApp loading={getAssessment.isLoading}>
                <Row className="row-info">
                    <Col span={14}>
                        <p className='p-row-info'>
                            <img className='icon-h' src={usenameIocn} />
                            {assessments?.fname + " " + assessments?.lname}
                        </p>
                    </Col>
                    <Col span={10}> 
                        <p className='p-row-info'>{assessments?.age} Y ,  {Gender[assessments?.gender]} , {assessments?.weight} Kg</p>
                    </Col>
                </Row>
                <Row className="row-info">
                    <Col span={12}>
                        <p className='p-row-info'>
                            <img className='icon-h' src={birthdayIocn} /> DOB: {moment(assessments?.birth_date)?.format(dateFormatMonth)}
                        </p>
                    </Col>
                    <Col span={12}>
                        <p className='p-row-info'>
                            <img className='icon-h' src={group2052Iocn} /> Id: {patientId}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}> <p className='p-row-info'>Severe Asthma</p></Col>
                </Row>
                </LoaderApp>
            </Col>

            <Col span={4} className='col-p-i-card one'>
                <Card
                    title="Assessment Details"
                    size={"small"}
                    bordered={false}
                    className='p-i-card one'
                    loading={getAssessment.isLoading}
                >
                    <p>ID: {assessments?.id} .....Current Step: {assessments?.current_step} /5  </p>
                    <p>Date: {moment(assessments?.date)?.format(dateFormatMonth)}</p>
                    <p>Practitioner: {assessments?.practitioner}</p>
                </Card>

            </Col>
            <Col span={8} className='col-p-i-card'>
                <Card
                    title="Clarity Notes"
                    size={"small"}
                    bordered={false}
                    className='p-i-card three'
                    loading={getClarityNote.isLoading}
                >
                    <Row>
                        <p className="title-ac">Missing attributes: </p> 
                        <p>{getClarityNote?.data?.missing_attributes?.map((missing:any) => (missing + ", "))}</p>
                    </Row>
                    <Row>
                        <p className="title-ac">Out of date attributes: </p> 
                        <p>{getClarityNote?.data?.old_attributes?.map((old:any) => (old.name + ", "))}</p>
                    </Row> 
                </Card> 
            </Col>
        </Row>
    );
}
export default PatientInfo;