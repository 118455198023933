import FormElement from "../../../../components/form-element";
import { IFormStep } from "../../../../models/form-step";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Col, Row } from "antd";

interface ITopRightProps {
    data: any;
}

const TopRight: FunctionComponent<ITopRightProps> = ({ data }) => {

    const { control, reset, handleSubmit } = useForm<IFormStep>({
        mode: "onBlur",
        defaultValues: {},
        shouldFocusError: true,
    });

    return (
        <div className="section t-r">
            {data?.options?.map((item: any) => (
                <Row justify="center" align="middle">
                    <Col span={20}> 
                        <Checkbox 
                            defaultChecked={item?.value} 
                            name={item?.label}
                            disabled={true}
                            className='ant-custom-input'
                        >
                            {item?.label}
                        </Checkbox>
                    </Col>
                </Row>
            ))
            }
        </div>
    );

}

export default TopRight;