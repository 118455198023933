import { FunctionComponent, useEffect, useRef } from 'react';

interface PsPdfKitProps {
    documentPath: string;
    setFormFieldValue: any;
}

const PsPdfKit: FunctionComponent<PsPdfKitProps> = ({ documentPath, setFormFieldValue }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const container = containerRef.current;

        if (container && typeof window !== 'undefined') {
            import('pspdfkit').then((PSPDFKit: any) => {
                if (PSPDFKit) {
                    PSPDFKit.unload(container);
                }

                PSPDFKit.load({
                    container,
                    document: documentPath,
                    baseUrl: `${window.location.protocol}//${window.location.host}/`,
                }).then((instance: any) => {
                    // console.log("PSPDFKit for Web successfully loaded!!", instance);
                    // You can find an introductions to annotations in our guides:
                    // https://pspdfkit.com/guides/web/current/forms/introduction-to-forms/
                    instance.getFormFields().then(function (formFields: any) {
                        console.log("All form fields", formFields.toJS());
                        setFormFieldValue(formFields.toJS(), instance);
                    });

                    // instance.textLinesForPageIndex(0).then(function (textLines: any) {
                    //     textLines.forEach((textLine: any, textLineIndex: any) => {
                    //         console.log(`Content for text line ${textLineIndex}`);
                    //         console.log(`Text: ${textLine.contents}`);
                    //         // console.log(`Id: ${textLine.id}`);
                    //         // console.log(`Page index: ${textLine.pageIndex}`);
                    //         console.log(`Bounding box: ${JSON.stringify(textLine.boundingBox.toJS())}`);
                    //     });
                    // });

                    // console.log("Annotation updated.");
                    // instance.setFormFieldValues({
                    //     "Patient Info T.1": "First name",
                    //     "Patient Info T.2": "Last Name",
                    // });
                    return instance;
                });
            });
        }
    }, []);

    return <div ref={containerRef} style={{ height: '100vh' }} />;
};

export default PsPdfKit;
