import { useParams } from "react-router-dom"; 
import { planColumns } from "./data";
import PlanForm from "./forms";
import GeneralTable from "../../../components/general-table";
import plan from "../../../lib/api/plan";
import { Divider } from "antd";
import { PlanType } from "../../../constants/enums";
import { attachmentMapper } from "../../../lib/utils";
import _ from "lodash";

const PlanPage = () => {
    const { id } = useParams();

    return (
        <GeneralTable
            dataName="Plan"
            headerTable="Payer Plans"
            getAllFunc={()=>plan.getAll({payer_id: Number(id)})}
            getFunc={plan.Get}
            delete
            deleteFunc={plan.Delete}
            create
            createFunc={plan.Create}
            update
            updateFunc={plan.Update}
            formContent={PlanForm}   
            tableColumns={planColumns}  
            tableDataMapper={(rows) => {
                return rows?.map((row: any, index: number) => {
                    return {
                        ...row, 
                        plan_type: PlanType[row?.plan_type]
                    };
                });
            }}
            FromDataMapper={(data: any) => {
                return {
                    ...data,   
                };
            }}  
            FormSubmitMapper={(data: any) => {
                return {
                    ...data,
                    pa_forms: data?.pa_forms.map((pa_form:any)=>({
                        ...pa_form,
                        ...attachmentMapper(['attachment'], pa_form, false), 
                    })),
                    payer: Number(id)
                };
            }}   
        />
    );
}
export default PlanPage;